import React, { useState, useEffect } from 'react';
import styles from "./SellersProductList.module.scss";
import Filter from "../ViewAll/assets/filter.svg";
import Sort from "../ViewAll/assets/sort.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SellerCard from './SellerCard';
import { VenderList } from '../../redux/slice/venderListSlice';
import Nodata from '../../constants/Nodata';
import { Outlet } from 'react-router-dom';
import { cityList } from '../../redux/slice/getCities';
import SellerCategoriesSwipper from './SellerCategoriesSwipper';
import { sellerCategories } from '../../services/sellerregistration.service';
import { ADMIN } from '../../services/admin.service';

const SellersProductList = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const SPID = localStorage.getItem("spCategory");
  const laung = localStorage.getItem('laung');

  const Sorting = [
    {
      "id": "1",
      "title": "A-Z"
    },
    {
      "id": "2",
      "title": "Z-A"
    },
  ];



  const [showFilters, setShowFilters] = useState(false);
  const [showsort, setShowsort] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(SPID ? SPID : "");
  const [sortingId, SetSortingId] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [categories, setCategories] = useState([]);

  const vendorList = useSelector((state) => state?.vendorList?.data?.response);
  const cityDetails = useSelector((state) => state?.cityList?.data?.response);

  useEffect(() => {
    dispatch(VenderList({ selectedFilter, sortingId, selectedCity }))
  }, [selectedFilter, sortingId, selectedCity])

  useEffect(() => {
    sellerCategories()
      .then(res => {
        if (res.data.status) {
          setCategories(res.data.response)
        }
      })
      .catch(e => ADMIN.toast.error(e.message))
  }, []);

  useEffect(() => {
    dispatch(cityList());
  }, [])

  const handleSelectSorting = (subcat) => {
    if (sortingId == subcat.id) {
      SetSortingId("")
    } else {
      SetSortingId(subcat.id)
    }
  };

  const handleSelectCity = (city) => {
    if (selectedCity == city.id) {
      setSelectedCity("")
    } else {
      setSelectedCity(city.id)
    }
  }
  
  return (
    <div className={styles.container} >
      <div className={styles.filter_listing} >
        <section className={styles.stores_count} >
          <div>
            <p className={styles.listing_count} style={{ fontFamily: "Cairo-Bold" }} > {t("Service Provider")}</p>
          </div>
          <section className={styles.filters} >
            <p onClick={() => setShowsort(!showsort)} style={{ fontFamily: "Cairo-Regular" }} ><img src={Sort} alt="" />{t("sort")}</p>
            <p onClick={() => setShowFilters(!showFilters)} style={{ fontFamily: "Cairo-Regular" }} ><img src={Filter} alt="" />{t("filters")}</p>
          </section>
        </section>
        {
          showsort &&
          <div className={styles.fliter_list} >
            <p style={{ fontFamily: "Cairo-Semibold" }} >{t("sort")}</p>
            <div className={styles.filter_types}  >
              {Sorting.map((subcat, indi) => <p style={{ border: sortingId === subcat.id ? "2px solid #2A2821" : " 1px solid #D9CBA0", fontFamily: "Cairo-Semibold" }} onClick={() => handleSelectSorting(subcat)} key={indi} >{subcat?.title}</p>)
              }
            </div>
          </div>
        }
        {(cityDetails && showFilters) &&
          <div className={styles.fliter_list} >
            <p style={{ fontFamily: "Cairo-Semibold" }} >{t("Cities")}</p>
            <div className={styles.filter_types}  >
              {cityDetails.map((city, indi) => <p style={{ border: selectedCity === city.id ? "2px solid #2A2821" : " 1px solid #D9CBA0", fontFamily: "Cairo-Semibold" }} onClick={() => handleSelectCity(city)} key={indi} >{city?.title}</p>)}
            </div>
          </div>}
        {/* <p className={styles.categories_label} style={{ fontFamily: "Cairo-Semibold" }} >{t("categories")}</p> */}

        {categories.length != 0 &&
        <SellerCategoriesSwipper
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          categories={categories}
        />}
        {categories.length != 0 && <h1 className={styles.providers_list} style={{ fontFamily: "Cairo-Bold" }} >{categories.filter((cat)=> cat.id == selectedFilter)[0]?.title}</h1>}
      </div>
      {
        vendorList?.length != 0 ?
          <div className={styles.seller_list_body} >
            <section className={styles.seller_cards} >
              {
                vendorList && vendorList.map((data, indi) => {
                  return (
                    <SellerCard key={indi} vendor={data} />
                  )
                })
              }
            </section>
          </div> : <Nodata laung={laung} />
      }
      <Outlet />
    </div>
  )
}

export default SellersProductList