import React from 'react';
import styles from "./TermsandCondition.module.scss";
import { useSelector } from 'react-redux';
import { H1, P } from '../../constants/CustomText/VendorHeading';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const laung = (localStorage.getItem('laung'));
  const termsCondtions = useSelector((state) => state?.termsandPrivacy.data?.response);
  return (
    <div className={styles.container} >
      <header className={styles.header_details}>
        <div className={styles.terms_heading} >
          <H1>{t("vender privacy")}</H1>
        </div>
      </header>
      <div className={styles.terms_body} >
        {termsCondtions && <div className={styles.body} dangerouslySetInnerHTML={{ __html: termsCondtions.privacy_policy }} style={{ fontFamily: laung == "ar" ? "Cairo-Regular" : "avirain_mediam" }} />}
      </div>
    </div>
  )
}

export default PrivacyPolicy;