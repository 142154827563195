import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

// action for the home page details:
export const singelOrderChatDetails = createAsyncThunk("singeOrderChatDetails", async (vID) => {
  const response = await axiosInstance.get(`get_chat?order_vendor_id=${vID}`)
  return response.data;
})

const singeOrderChatSlice = createSlice({
  name: "OrderChatDetails",
  initialState:{
    isLoading:false,
    data:null,
    isError : false,
  },

  extraReducers: (builder) => {
    builder.addCase(singelOrderChatDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(singelOrderChatDetails.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(singelOrderChatDetails.rejected, (state, action) => {
      state.isError = true;
    })
  }
});

export default singeOrderChatSlice.reducer;