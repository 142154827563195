import React, { Fragment } from 'react';
import styles from "./SellerCard.module.scss";
import Mail from '../../assets/Images/svgs/mail.svg';
import Call from '../../assets/Images/svgs/call.svg';
import Web from '../../assets/Images/svgs/web.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const SellerCard = ({ vendor }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const laung = (localStorage.getItem('laung'));

  const handleNavigate = (vend) => {
    navigate(`seller_details/${vend.id}`)
  };
  const NavigateToMail = (vendor) => {
    const email = vendor.email_id;
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
  };

  let upDatedURL;
  if (vendor.website.includes("https://")) {
    upDatedURL = vendor.website;
  } else {
    upDatedURL = `https://${vendor.website}`;
  }

  return (
    <div className={styles.container} >
      <div className={styles.container_left} >
        {/* <p className={laung == "ar" ? styles.offer_tag_arabic : styles.offer_tag} >{t("extra")} {15} % {t("off")} </p> */}
        <img
          src={vendor.profile_image}
          alt=""
          className={styles.seller_picture}
          onClick={() => handleNavigate(vendor)}
        />
      </div>
      <div className={styles.container_right} >
        <div className={styles.only_details} onClick={() => handleNavigate(vendor)} >
          <h1 className={styles.seller_name} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >
            {t("business name")}:{vendor.business_name}
          </h1>
          <div className={styles.seller_address} >
            <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("categories")}: {vendor.sp_category.map((categoty, indi) => <Fragment key={indi}>  {categoty.title},</Fragment>)} </p>
            <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("address")}: {vendor.business_address}</p>
            <p style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("city")}: {vendor.city}</p>
          </div>
          <div className={styles.seller_address} >


            <span style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
              {t("Descriptions")}:
              <span className={styles.description_details}  dangerouslySetInnerHTML={{ __html: vendor.service_description }} />
            </span>
          </div>
          <div className={styles.seller_status} >
            <p style={vendor.open_now == "1" ? { color: "#1AC300", fontFamily: laung == "ar" && "Cairo-Regular" } :
              { color: "red", fontFamily: laung == "ar" && "Cairo-Regular" }} >{vendor.open_now == "1" ? "" : ""}
              {/* t("open") :  t("closed")  */}
              <span>{t("Working Hours")} ( {vendor.open_by} - {vendor.close_by} )</span> </p>
          </div>
        </div>

        <div className={styles.seller_contacts} >
          {vendor.mobile_number &&
            <a href={`tel:${vendor.country_code}${vendor.mobile_number}`}>
              <div className={styles.contact_by_number} >
                <img
                  src={Call}
                  alt="Call"
                />
                <p style={{ direction: "ltr", textAlign: laung == "ar" ? "right" : "left", fontFamily: laung == "ar" && "Cairo-Semibold" }} >{vendor.country_code}-{vendor.mobile_number}</p>
              </div>
            </a>}
          {vendor.website &&
            <a href={vendor.website.includes("https://") ? vendor.website : `https://${vendor.website}`} target="_blank" rel="noopener noreferrer" className={styles.contact_by}  >
              <img
                src={Web}
                alt="website"
              />
              <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{vendor.website}</p>
            </a>}
          {vendor.email_id &&
            <div className={styles.contact_by} onClick={() => NavigateToMail(vendor)} >
              <img
                src={Mail}
                alt="Mail"
              />
              <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }}>{vendor.email_id}</p>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default SellerCard