import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getProductDetails = createAsyncThunk("productDetails", async (pID) => {
  const response = await axiosInstance.get(`product?product_id=${pID}`)
  return response.data;
});


const getProductDetailsSlice = createSlice({
  name:"ProductDetails",
    initialState: {
      isLoading : false,
      data : null,
      isError : false
    },
  
    extraReducers: (builder) => {
      builder.addCase(getProductDetails.pending, (state, action) => {
        state.isLoading = true;
      });
  
      builder.addCase(getProductDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  
      builder.addCase(getProductDetails.rejected, (state, action) => {
        state.isError = true;
      })
    }
  });

  export default getProductDetailsSlice.reducer;