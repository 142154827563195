// react 
import React from 'react';
import styles from "./BouncingLoader.module.scss";

const BouncingLoader = () => {
    return (
      <div className={styles.loader}>
      <span></span>
      <span></span>
      <span></span>
    </div>
    );
};

export default BouncingLoader;