import React, { useEffect, useRef, useState } from 'react';
import styles from "./HeaderViewAll.module.scss";
import downicon from "../../assets/Images/svgs/down_icon.svg"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SubCategoryList = ({
  data,
  cat,
  handleOpenDrawer,
  subcategoryDrop,
  handleSubCategory,
  handleCloseDrawer,
  selectedSubCat,
}) => {
  const params = useParams()
  const laung = localStorage.getItem("laung");
  const numRows = data && Math.ceil(data.length / 5)
  const { sub_sub_category } = params;
  if (numRows == 0) { return };
  
  return (
    <div style={{ display: 'flex' }} className={`${styles.sub_menu} ${subcategoryDrop ? styles.open : ''}`} >
      {numRows && [...Array(numRows)].map((_, rowIndex) => (
        <div key={rowIndex} className={styles.sub_row}
          onMouseEnter={() => { handleOpenDrawer(cat) }}
          onMouseLeave={() => { handleCloseDrawer(cat) }}
        >

          {data.slice(rowIndex * 5, (rowIndex + 1) * 5).map((item, index) => (
            <div key={index} style={{ marginRight: '20px' }} className={styles.menu_left}>
              <p
                onClick={() => handleSubCategory(item, cat)}
                style={{
                  fontFamily: laung == "ar" ? "Cairo-Medium" : "avirain_mediam",
                  textDecoration: item.url === sub_sub_category ? "underline" : "",
                  // textDecoration: item.id === selectedSubCat ? "underline" : item.url === sub_sub_category ? "underline" : "",
                }}
              >{item.title}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};


const HeaderViewAll = ({
  categories,
  setPageNumber,
  setSortID,
  setSelectedSubcat,
  setProductLoader,
  selectedSubCat,
  setSelectedBrands
}) => {

  const { t } = useTranslation();
  const params = useParams();
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const { categoryname1, subcategory } = params;
  const laung = localStorage.getItem("laung");
  const { state } = useLocation();
  const categoryname = localStorage.getItem('categoryname');

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const subCID = urlParams.get('subCID');

  const [subcategoryDrop, setSubCategoryDrop] = useState(false)
  const [subCategorydata, setSubCategorydata] = useState();
  const [showMoreCategories, setShowmoreCategories] = useState(false)

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    setShowmoreCategories(!showMoreCategories)
  };

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      const selectedIndex = categories.findIndex((category) => category.id === state?.subCID);
      if (swiperRef.current && swiperRef.current.swiper) {
        const currentPathname = window.location.pathname;
        const previousPathname = localStorage.getItem('previousPathname');

        if (currentPathname !== previousPathname) {
          localStorage.setItem('previousPathname', currentPathname);
        } else {
          swiperRef.current.swiper.slideTo(selectedIndex);
        }
      }
    }
  }, [subcategory, state?.subCID]);

  const visibleCategories = showMoreCategories ? categories : categories && categories.slice(0, 10);

  const removingFilters = () => {
    let keysToRemove = ["filteredCities", "filteredSubCategories", "filteredBrands", "filterSortingID", "filterCustomerReview", "minimum_price", "maximum_price", "price_range"];
    keysToRemove.forEach(k => localStorage.removeItem(k));
  };

  const handleChangeCategory = (cat) => {
    setProductLoader(true)
    removingFilters()
    if (cat.url == subcategory) {
      setSelectedSubcat([])
      localStorage.setItem("filteredSubCategories", "")
    } else {
      // setLoading(true);
      setSortID("");
      setSelectedSubcat("");
      setPageNumber(0);
      setSelectedBrands("")
      if (laung == "en") {
        navigate(`/${laung}/category/${categoryname}/${cat.url}`, { state: { subCID: cat.id } });
      } else {
        navigate(`/category/${categoryname}/${cat.url}`, { state: { subCID: cat.id } });
      }
    }
  };

  const handleOpenDrawer = (category) => {
    setSubCategorydata(category)
    setSubCategoryDrop(true)
  };

  const handleCloseDrawer = () => {
    setSubCategorydata("")
    setSubCategoryDrop(false)
  }

  const handleSubCategory = (subcat, cat) => {
    removingFilters()
    setProductLoader(true)
    if (cat.url !== subcategory) {
      // setLoading(true);
      setSortID("");
      setPageNumber(0);
      if (laung == "en") {
        navigate(`/${laung}/category/${categoryname}/${cat.url}/${subcat.url}`, { state: { subCID: cat.id } });
      } else {
        navigate(`/category/${categoryname}/${cat.url}/${subcat.url}`, { state: { subCID: cat.id } });
      }
    }
    
    if (laung == "en") {
      setPageNumber(0);
      navigate(`/${laung}/category/${categoryname}/${cat.url}/${subcat.url}`, { state: { subCID: cat.id } });
    } else {
      setPageNumber(0);
      navigate(`/category/${categoryname}/${cat.url}/${subcat.url}`, { state: { subCID: cat.id } });
    }
    setSubCategoryDrop(false)
    setSelectedSubcat([subcat.id])
    // localStorage.setItem("filteredSubCategories", subcat.id)
  };
  
  return (
    <div className={styles.container}>
      <div className={`${styles.categories_name} ${isExpanded ? '' : styles.expanded}`} >
        {visibleCategories && visibleCategories.map((cat, indi) => {
          return (
            <div
              className={`${subCategorydata?.id == cat?.id ? styles.category_open : ''}`}
              onMouseEnter={() => { handleOpenDrawer(cat) }}
              onMouseLeave={() => { handleCloseDrawer(cat) }}>
              <a
                key={indi}
                className={styles.category}
                onClick={() => handleChangeCategory(cat)}
              >
                <p style={{
                  fontFamily: laung == "ar" ? "Cairo-Medium" : "avirain_mediam",
                  borderBottom:  cat.url === subcategory ? "2px solid #8E805B" : "",
                  paddingBottom: "5px",
                }} >
                  {cat?.title}
                </p>
                {cat.sub_category.length != 0 && <img src={downicon} alt="down" />}
              </a>

              {subCategorydata?.id == cat?.id &&
                <SubCategoryList
                  data={subCategorydata.sub_category}
                  cat={cat}
                  selectedSubCat={selectedSubCat}
                  handleOpenDrawer={handleOpenDrawer}
                  subcategoryDrop={subcategoryDrop}
                  handleSubCategory={handleSubCategory}
                  handleCloseDrawer={handleCloseDrawer}
                />
              }
            </div>
          );
        })}
        {categories && categories.length > 10 && <button className={styles.expandButton} onClick={toggleExpanded}>{isExpanded ? `${t("View less")}` : `${t("View more")}`}{isExpanded ? <i className='fa fa-angle-up'></i> : <i className='fa fa-angle-down'></i>}</button>}

      </div>
    </div>
  )
}

export default HeaderViewAll;