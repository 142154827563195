import React, { useEffect, useState } from 'react'
import styles from "./Filters.module.scss";
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import ActiveStar from "../../assets/Images/svgs/goldernstar.svg";
import InActiveStar from "../../assets/Images/svgs/inactive_star.svg";
import Rating from 'react-rating';
import { ADMIN } from '../../services/admin.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


const Filters = ({
  setSelectedSubcat,
  setPriceRange,
  setFilterDrawer,
  handleNotScrollForFilter,
  clearFilter,
  selectedSubCat,
  setSelectedBrands,
  selectedBrands,
  setSortID,
  setOrderRatings,
  orderRatings,
  setSelectedCities,
  selectedCities,
  setPageNumber,
  setMaxPrice,
  setMinPrice,
  categories,
  setProductLoader,
}) => {


  const maximumprice = localStorage.getItem("maximum_price");
  const minimumprice = localStorage.getItem("minimum_price");
  const localSubCat = localStorage.getItem("filteredSubCategories");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subcategory, vendor_id } = useParams()
  const laung = localStorage.getItem("laung");
  const categoryname = localStorage.getItem('categoryname');
  const vendorProducts = localStorage.getItem("browse_vendor")
  const filterList = useSelector((state) => state?.filterDetails?.data?.response);
  const [selectedSubCategories, setSelectedSubCategories] = useState(selectedSubCat ?? localSubCat);
  const [selectedCity, setSelectedCity] = useState(selectedCities)
  const [selectBrand, setSelectBrand] = useState(selectedBrands);
  const [brandSearch, setBrandSearch] = useState("");
  const [subCategoriesSearch, setSubcategoriesSearch] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [showMoreCity, setShowMoreCity] = useState(false);
  const [shoeMoreSubCat, setShowMoreSubCat] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [fromPrice, setFromPrice] = useState(minimumprice ? minimumprice : "0");
  const [toPrice, setToPrice] = useState(maximumprice ? maximumprice : filterList.max_price);
  const [openAccrodion, setAccrodion] = useState("")

  const RatingReviews = [
    {
      "ratings": 4
    },
    {
      "ratings": 3
    },
    {
      "ratings": 2
    },
    {
      "ratings": 1
    },
  ]

  const sliderValues = [fromPrice, toPrice]

  const handleChange = (newValue) => {
    setPageNumber(0)
    setFromPrice(newValue[0]);
    setMinPrice(newValue[0])
    setToPrice(newValue[1]);
    setMaxPrice(newValue[1])
    setPriceRange(newValue)
    localStorage.setItem("minimum_price", newValue[0])
    localStorage.setItem("maximum_price", newValue[1])
  };


  const handleFromPrice = (e) => {
    const inputValue = e.target.value;

    if (!isNaN(inputValue)) {
      const numericValue = parseFloat(inputValue);

      if (numericValue < 0) {
        ADMIN.toast.warn(t("Negative Values are not allowed"))
      } else {
        setPageNumber(0)
        setFromPrice(numericValue)
        setMinPrice(numericValue)
        setPriceRange((state) => [state[0], numericValue]);
        localStorage.setItem("minimum_price", numericValue)
      }
    }
  };

  const handleToPrice = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      const numericValue = parseFloat(inputValue);
      if (numericValue < 0) {
        ADMIN.toast.warn(t("Negative Values are not allowed"))
      } else {
        setPageNumber(0)
        setToPrice(e.target.value)
        setMaxPrice(e.target.value);
        setPriceRange((state) => [state[1], e.target.value]);
        localStorage.setItem("maximum_price", e.target.value)
      }
    }

  };


  const handleSubcategory = (e, id, categorie) => {
    setProductLoader(true)
    if (categorie.url !== subcategory) {
      clearFilter()
      setPageNumber(0);
      if (laung == "en") {
        navigate(`/${laung}/category/${categoryname}/${categorie.url}`, { state: { subCID: categorie.id } });
      } else {
        navigate(`/category/${categoryname}/${categorie.url}`, { state: { subCID: categorie.id } });
      }
    }
    if (id == localSubCat) {
      setSelectedSubCategories("")
      setSelectedSubcat("")
      localStorage.setItem("filteredSubCategories", "")
      setFilterDrawer(false)
      return
    }
    setSelectedSubCategories([id])
    setSelectedSubcat([id])
    localStorage.setItem("filteredSubCategories", id)
    setFilterDrawer(false)
  };

  const handleFilterSubcategory = (sub_cat) => {
    setPageNumber(0)
    setProductLoader(true)
    if (selectedSubCategories == sub_cat.id) {
      localStorage.setItem("filteredSubCategories", "")
      setSelectedSubCategories("")
      setSelectedSubcat("")
      return
    } else {
      // if (laung == "en") {
      //   navigate(`/${laung}/category/${categoryname}/${subcategory}/${sub_cat.url}`, { state: { subCID: sub_cat.id } });
      // } else {
      //   navigate(`/category/${categoryname}/${subcategory}/${sub_cat.url}`, { state: { subCID: sub_cat.id } });
      // }
      setSelectedSubCategories(sub_cat.id)
      setSelectedSubcat(sub_cat.id)
      localStorage.setItem("filteredSubCategories", sub_cat.id)
    }
  }

  const handleBrands = (bid) => {
    setProductLoader(true)
    const isPresent = selectedBrands.includes(bid.id);
    setPageNumber(0)
    if (isPresent) {
      const updatedBrands = selectedBrands.filter((brands) => brands !== bid.id)
      localStorage.setItem("filteredBrands", updatedBrands)
      setSelectedBrands(updatedBrands)
      setSelectBrand(updatedBrands)
    } else {
      setSelectedBrands((state) => [...state, bid.id])
      setSelectBrand((state) => [...state, bid.id])
      localStorage.setItem("filteredBrands", [...selectBrand, bid.id])
    }
  }

  const handleCities = (CID) => {
    setPageNumber(0)
    setProductLoader(true)
    if (selectedCity == CID.id) {
      localStorage.setItem("filteredCities", "")
      setSelectedCities("")
      setSelectedCity("")
    } else {
      setSelectedCities(CID.id)
      setSelectedCity(CID.id)
      localStorage.setItem("filteredCities", CID.id)
    }
  }

  const handleApplyFilter = () => {
    setProductLoader(true)
    setPageNumber(0)
    if (Number(toPrice) > Number(fromPrice)) {
      handleNotScrollForFilter()
      setFilterDrawer(false)

    } else {
      setProductLoader(false)
      ADMIN.toast.warn(t("To Price should not be lesser than From Price"))
    }
  };

  const handleClearFilters = () => {
    clearFilter()
    setBrandSearch("");
    setSubcategoriesSearch("");
    setSearchCity("")
    setPageNumber(0)
    setToPrice(filterList?.max_price)
    setFromPrice(0)
    setFilterDrawer(false)
    setSelectBrand([]);
    setSelectedCity([])
    setSelectedBrands([])
    setSelectedSubCategories([])
    setSortID(4);
    let keysToRemove = [
      "filteredCities",
      "filteredSubCategories",
      "filteredBrands",
      "filterSortingID",
      "filterCustomerReview",
      "price_range",
      "maximum_price",
      "minimum_price"
    ];
    keysToRemove.forEach(k => localStorage.removeItem(k));
  };


  const handleFilterRating = (rattings) => {
    setProductLoader(true)
    setPageNumber(0)
    if (orderRatings === rattings) {
      setOrderRatings("");
      localStorage.removeItem("filterCustomerReview")
      return
    } else {
      setOrderRatings(rattings)
      localStorage.setItem("filterCustomerReview", rattings)
    }
  }

  useEffect(() => {
    setAccrodion(subcategory)
  }, [])

  // filtered brands:
  const filteredBrands = filterList && filterList.brand.filter((brand) =>
    brand.title.toLowerCase().includes(brandSearch.toLowerCase())
  );
  const visibleBrands = showMore ? filteredBrands : filteredBrands && filteredBrands.slice(0, 6);

  // filteres sub cateogires:
  const filteredSubCategories = filterList && filterList.sub_category.filter((subcat) =>
    subcat.title.toLowerCase().includes(subCategoriesSearch.toLowerCase())
  );
  const visibleSubcategories = shoeMoreSubCat ? filteredSubCategories : filteredSubCategories && filteredSubCategories.slice(0, 6);

  // filteres for cities:
  const filteredCities = filterList && filterList.city.filter((city) =>
    city.title.toLowerCase().includes(searchCity.toLowerCase())
  );
  const visibleCities = showMoreCity ? filteredCities : filteredCities && filteredCities.slice(0, 6);

  const hanldeAccordian = (cat) => {
    if (cat.sub_category.length != 0) {
      if (openAccrodion == cat.url) {
        setAccrodion("")
        return
      } else {
        setAccrodion(cat.url)
      }
    } else {
      clearFilter()
      setPageNumber(0); setFilterDrawer(false);
      navigate(laung == "en" ? `/${laung}/category/${categoryname}/${cat.url}` : `/category/${categoryname}/${cat.url}`)
    }
  }

  const subCategories = (category) => {
    return (
      <>
        {
          Array.isArray(category.sub_category) && category?.sub_category.map((subcategory, subcategory_index) => {
            return (
              <p key={subcategory_index} className={styles.brand_p} >
                <Checkbox
                  checked={selectedSubCategories == subcategory.id}
                  onChange={(e) => handleSubcategory(e, subcategory.id, category)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <span style={{ fontFamily: "Cairo-Medium" }}> {subcategory.title}</span>
              </p>
            )
          })
        }
      </>
    )
  }

  const OnlySubcategories = (subcategories) => {
    return (
      <>
        {
          Array.isArray(subcategories) && subcategories.map((subcategory, subcategory_index) => {
            return (
              <p key={subcategory_index} className={styles.brand_p} >
                <Checkbox
                  checked={selectedSubCategories == subcategory.id}
                  onChange={(e) => handleFilterSubcategory(subcategory)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <span style={{ fontFamily: "Cairo-Medium" }}> {subcategory.title}</span>
              </p>
            )
          })
        }
      </>
    )
  }

  const SubcategoryTitle = categories && categories.filter((cat) => cat.url === subcategory)[0]?.sub_category.filter((subcat) => subcat.id == selectedSubCat)[0]?.title;
  const CategoryTitle = categories && categories.filter((cat) => cat.url === subcategory)[0]?.title;

  return (
    <div className={styles.the_filter_drawer} >
      <Box>
        <p className={styles.total_products} >
          {SubcategoryTitle ? SubcategoryTitle : CategoryTitle}
        </p>
        {
          <div className={styles.sorting_data} >
            <div className={styles.filter_apply_filter} >
              <p className={styles.sort_heading} style={{ fontFamily: "Cairo-Medium", fontWeight: "bolder" }}> {t("filters")}</p>
              <div className={styles.filter_buttons} >
                <button
                  className={styles.apply_filter}
                  onClick={() => handleClearFilters()}
                  style={{ fontFamily: "Cairo-Medium" }}
                >{t("clear filters")}
                </button>
              </div>
            </div>
            <div className={styles.sorting_list} >
              <div className={styles.price_range} >
                <p style={{ paddingBottom: "5px", fontFamily: "Cairo-Medium" }}>{t("price range")} {t("in SAR")} SAR</p>
                <div className={styles.price_inputs} >
                  <div className={styles.from_price} >
                    <p className={styles.price_lable} style={{ fontFamily: "Cairo-Medium" }} >{t("from")}</p>
                    <div className={styles.only_input} >
                      <p className={styles.price_lable} style={{ fontFamily: "Cairo-Medium" }}>SAR</p>
                      <input
                        type='number'
                        value={fromPrice}
                        onChange={(e) => handleFromPrice(e)}
                      />
                    </div>
                  </div>
                  <div className={styles.from_price} >
                    <p className={styles.price_lable} style={{ fontFamily: "Cairo-Medium" }}>{t("to")}</p>
                    <div className={styles.only_input}>
                      <p className={styles.price_lable} style={{ fontFamily: "Cairo-Medium" }}>SAR</p>
                      <input
                        type='number'
                        max={filterList.max_price}
                        value={toPrice}
                        onChange={(e) => handleToPrice(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.range_details} >
                  <Slider
                    range
                    allowCross={false}
                    reverse={laung == "ar" ? true : false}
                    max={filterList.max_price ? Number(filterList.max_price) : 0}
                    defaultValue={sliderValues}
                    onChange={handleChange}
                    value={[fromPrice, toPrice]}
                  />
                </div>
                <div className={styles.filter_buttons}>
                  <button
                    className={styles.apply_filter}
                    onClick={() => handleApplyFilter()}
                    style={{ fontFamily: "Cairo-Medium" }}
                  >{t("apply filters")}
                  </button>
                </div>
              </div>
              {
                categories &&
                <div className={styles.category_list_category} >
                  {
                    categories.map((category) => {
                      return (
                        <Grid xs={12} lg={12} key={category.id} >
                          <Accordion
                            key={category.id}
                            style={{ backgroundColor: "#fff", marginTop: "20px" }}
                            expanded={openAccrodion === category.url}
                            onClick={() => hanldeAccordian(category)}
                          >
                            <AccordionSummary
                              expandIcon={category.sub_category.length != 0 && <ExpandMoreIcon fontSize='large' sx={{ color: "#2A2821" }} />}
                            >
                              <p className={styles.category_heading} style={{ fontFamily: "Cairo-Medium" }}>{category.title}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                              {subCategories(category)}
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )
                    })
                  }
                </div>
              }
              {
                vendor_id && filteredSubCategories &&
                <div className={styles.category_list_category} >
                  <p className={styles.category_heading} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Avenir Black" }}>{t("Sub Categories")}</p>
                  {OnlySubcategories(filteredSubCategories)}
                </div>
              }

              {/* SUb categorie wise filters */}
              {(filterList?.sub_category.length > 0) &&
                <div className={`${styles.category_list} ${styles.sub_category_list}`} >
                  <p className={styles.category_heading} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "Avenir Black" }}>{t("Sub Categories")}</p>
                  <input
                    className={styles.brand_search}
                    placeholder={t("search for Sub Categories")}
                    value={subCategoriesSearch}
                    style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "avirain_mediam" }}
                    onChange={(e) => setSubcategoriesSearch(e.target.value)}
                  />

                  {
                    filterList && Array.isArray(filterList.sub_category) && visibleSubcategories.map((subcategory, subcategory_index) => {
                      return (
                        <p key={subcategory_index} className={styles.brand_p} >
                          <input
                            type='checkbox'
                            className={styles.list_checkbox}
                            checked={selectedSubCat.includes(subcategory.id)}
                            onChange={(e) => handleFilterSubcategory(subcategory)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <span style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "avirain_mediam" }}> {subcategory.title}</span>
                        </p>
                      )
                    })
                  }
                  {filteredSubCategories.length > 6 && (
                    <p className={styles.show_more} style={{ fontFamily: laung == "ar" ? "Cairo-Medium" : "avirain_mediam" }} onClick={() => setShowMoreSubCat(!shoeMoreSubCat)}>{shoeMoreSubCat ? t("show less") : t("show more")}</p>
                  )}
                </div>
              }

              {
                filterList && filterList.brand.length > 0 &&
                <div className={styles.category_list} >
                  <p style={{ fontFamily: "Cairo-Medium" }} className={styles.category_heading}>{t("brands")}</p>
                  <input
                    className={styles.brand_search}
                    placeholder={t("search for brands")}
                    value={brandSearch}
                    style={{ fontFamily: "Cairo-Medium" }}
                    onChange={(e) => setBrandSearch(e.target.value)}
                  />
                  {
                    filterList && Array.isArray(filterList.brand) && visibleBrands.map((brands, brand_index) => {
                      return (
                        <p key={brand_index} className={styles.brand_p} >
                          {
                            brands.title && brands.id &&
                            <input
                              className={styles.list_checkbox}
                              type="checkbox"
                              checked={selectBrand.includes(brands.id)}
                              onChange={() => handleBrands(brands)}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          {brands.title && brands.id && <span style={{ fontFamily: "Cairo-Medium" }} >{brands.title}</span>}
                        </p>
                      )
                    })
                  }
                  {filteredBrands.length > 6 && (
                    <p className={styles.show_more} style={{ fontFamily: "Cairo-Medium" }} onClick={() => setShowMore(!showMore)}>{showMore ? t("show less") : t("show more")}</p>
                  )}
                </div>
              }

              {filterList && filterList.city.length > 0 &&
                <div className={styles.category_list} >
                  <p style={{ marginBottom: "15px", fontFamily: "Cairo-Medium" }} className={styles.category_heading}>{t("City")}</p>
                  <input
                    className={styles.brand_search}
                    placeholder={t("search for city")}
                    value={searchCity}
                    style={{ fontFamily: "Cairo-Medium" }}
                    onChange={(e) => setSearchCity(e.target.value)}
                  />
                  {
                    filterList && Array.isArray(filterList.city) && visibleCities.map((city, city_index) => {
                      return (
                        <p key={city_index} className={styles.brand_p} >
                          <input
                            type='checkbox'
                            className={styles.list_checkbox}
                            checked={selectedCity.includes(city.id)}
                            onChange={() => handleCities(city)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                          <span style={{ fontFamily: "Cairo-Medium" }} >{city.title}</span>
                        </p>
                      )
                    })
                  }
                  {filteredCities.length > 6 && (
                    <p className={styles.show_more} style={{ fontFamily: "Cairo-Medium" }} onClick={() => setShowMoreCity(!showMoreCity)}>{showMoreCity ? t("show less") : t("show more")}</p>
                  )}
                </div>
              }

              {filterList && filterList.product_count != 0 &&
                <div className={styles.category_list} >
                  <p style={{ marginBottom: "15px", fontFamily: "Cairo-Medium" }} className={styles.category_heading}>{t("Customer Reviews")}</p>
                  {
                    RatingReviews.map((rattings, rating_index) => {
                      return (
                        <div key={rating_index} className={styles.ratings} onClick={() => handleFilterRating(rattings.ratings)}  >
                          <Rating
                            initialRating={rattings.ratings}
                            readonly
                            direction={laung == "ar" ? "rtl" : "ltr"}
                            emptySymbol={<img src={InActiveStar} className="icon" alt="" style={{ height: "25px", width: "25px" }} />}
                            fullSymbol={<img src={ActiveStar} className="icon" alt="" style={{ height: "25px", width: "25px" }} />}
                          />
                          < p className={styles.rating_number} style={{ fontFamily: "Cairo-Medium", color: orderRatings === rattings.ratings ? "#000" : "" }} >{t("& Higher")} </p>
                        </div>
                      )
                    })
                  }
                </div>
              }
            </div>
          </div>
        }
      </Box>
    </div>
  )
}

export default Filters;