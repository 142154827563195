import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./swipper.css";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useSelector } from 'react-redux';
const BannerLoader = React.lazy(() => import('../../../../constants/BannerLoader'));


const Swipper = () => {
  const laung = localStorage.getItem("laung");
  const department_url = localStorage.getItem("categoryname");

  const details = useSelector((state) => state?.homePageDetails?.data?.response);
  const termsCondtions = useSelector((state) => state?.termsandPrivacy.data?.response);

  const NavigateToProductList = (vid) => {
    const newUrl = `${process.env.PUBLIC_URL}/all_products/vendor/${vid}`;
    const newUrlEn = `${process.env.PUBLIC_URL}/${laung}/all_products/vendor/${vid}`;
    window.open(laung == "en" ? newUrlEn : newUrl, '_blank');
    // navigate(laung == "en" ? newUrlEn: newUrl, '_blank');
  };
  const removingFilters = () => {
    let keysToRemove = ["filteredCities","browse_vendor", "filteredSubCategories", "filteredBrands", "filterSortingID", "filterCustomerReview", "minimum_price", "maximum_price", "searchTerm",];
    keysToRemove.forEach(k => localStorage.removeItem(k));
  };
  const handleNaviagte = (data) => {
    removingFilters()
    localStorage.setItem("fromsearch", false);
    localStorage.setItem("searchTerm", '')
    // localStorage.setItem("filteredSubCategories", data.master_id)
    if (data.type == "5") {
      NavigateToProductList(data.master_id)
    } else if (data.type == "2") {
      window.open(laung == "en" ? `/${laung}/category/${department_url}/${data.url}/${data.sub_category_url}` : `/category/${department_url}/${data.url}/${data.sub_category_url}`, "_blank");
    } else {
      const newUrl = `${process.env.PUBLIC_URL}/category/${department_url}/${data.url}`;
      const newUrlEN = `${process.env.PUBLIC_URL}/en/category/${department_url}/${data.url}`;
      window.open(laung == "en" ? newUrlEN : newUrl, "_blank");
    }
  };

  return (
    <div className={laung == "ar" ? "ar_caro_sli" : "caro_sli"}>
      {((Array.isArray(details?.banner) == true) || (Array.isArray(termsCondtions?.banner) == true)) == true ?
        <Swiper
          className={laung == "ar" ? "arabic_vers" : "eng_version"}
          dir={laung == "ar" ? "rtl" : "ltr"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
        >
          {(details && Array.isArray(details.banner) && details.banner.length > 0) ? (
            details.banner.map((data, index) => (
              <SwiperSlide key={index}>
                <img src={data.image} alt={data.alt_text} onClick={() => handleNaviagte(data)} />
              </SwiperSlide>
            ))
          ) : (
            termsCondtions && Array.isArray(termsCondtions.banner) && termsCondtions.banner.map((data, index) => (
              <SwiperSlide key={index}>
                <img src={data.image} alt={data.alt_text} onClick={() => handleNaviagte(data)} />
              </SwiperSlide>
            ))
          )}
        </Swiper>
        :
        <BannerLoader />
      }
    </div>
  )
}

export default Swipper;
