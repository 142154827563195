import axiosInstance from "./api/index";

export const registerSeller = async (data) => {
  try {
    const response = await axiosInstance.post("serviceprovider", data)
    return response
  } catch (error) {
    throw(error)
  }
};

export const singleSeller = async (sid) => {
  try {
    const response = await axiosInstance.get(`serviceprovider?id=${sid}`)
    return response
  } catch (error) {
    throw(error)
  }
};

export const sellerCategories = async () => {
  try {
    const response = await axiosInstance.get("sp_department")
    // const response = await axiosInstance.get("sp_category")
    return response
  } catch (error) {
    throw(error)
  }
};


export const registorVendor = async (data) => {
  try {
    const response = await axiosInstance.post("vendor", data)
    return response
  } catch (error) {
    throw(error)
  }
}

