import React, { useState } from 'react';
import styles from "./Account.module.scss";
import Sidebar from './sidebar/Sidebar';
import { Outlet } from "react-router-dom"
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';

const Account = () => {

  const { t } = useTranslation()
  const laung = (localStorage.getItem('laung'));
  const [openDrawer, setDrawer] = useState(false);

  const handleOpenDrawer = () => {
    setDrawer(true)
  };
  const handleClosreDrawer = () => {
    setDrawer(false);
  }

  return (
    <div className={styles.container} >
      <div className={styles.sidebar} > <Sidebar laung={laung} closeDrawer={handleClosreDrawer} /></div>
      <div className={laung == "ar" ? styles.sidebar_details_arabic : styles.sidebar_details}  > 
        <div className={styles.drawer_button} >
          <p onClick={() => handleOpenDrawer()} style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{t("my account")}</p>
          <Drawer
            className={laung == "ar" ? "arabic_vers" : "eng_version"}
            open={openDrawer}
            onClose={handleClosreDrawer}
          >
            <Sidebar laung={laung} closeDrawer={setDrawer} />
          </Drawer>
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Account