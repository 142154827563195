import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getDepartMentsAction = createAsyncThunk("getDepartments", async (laung) => {
  const response = await axiosInstance.get(`department?laung=${laung}`)

  const department_id =localStorage.getItem("departmentID");
  const department_url =localStorage.getItem("categoryname");
  const department_title =localStorage.getItem("department_title");
  if(department_id==null){
    localStorage.setItem("departmentID", response?.data?.response[0]?.id);
  }
  if(department_url==null){
    localStorage.setItem("categoryname", response?.data?.response[0]?.url);
  }

  if(department_title==null){
    localStorage.setItem("department_title", response?.data?.response[0]?.title);
  }

  return response.data;
});

const getDepartmentsSlice = createSlice({
  name: "DepartmentDetails",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(getDepartMentsAction.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getDepartMentsAction.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getDepartMentsAction.rejected, (state, action) => {
      state.isError = true;
    })
  }
});


export default getDepartmentsSlice.reducer;