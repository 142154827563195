import React from 'react'
import ContentLoader from 'react-content-loader'

const AboutUsLoader = () => {
  return (
    <ContentLoader viewBox="1 0 800 400" height={"100vh"} width={"90vw"}>
      <rect x="5%" y="8" rx="0" ry="0" width="45%" height="60%" />
      <rect x="55%" y="20" rx="0" ry="0" width="100%" height="15" />
      <rect x="55%" y="50" rx="0" ry="0" width="100%" height="15" />
      <rect x="55%" y="80" rx="0" ry="0" width="100%" height="15" />
      <rect x="55%" y="110" rx="0" ry="0" width="100%" height="15" />
      <rect x="55%" y="140" rx="0" ry="0" width="25%" height="15" />
    </ContentLoader>
  )
}

export default AboutUsLoader;