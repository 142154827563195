const HOST = "https://api.venderlane.com/web/v0_9/api/";

export const getLocalToken = () => {
    const token = (localStorage.getItem('venlogtoken'))
    if (token) {
        return token
    }
};

export const getHost = () => {
    return HOST
};

export const getHeaders = () => {
    let headers = {
        'Content-Type': 'application/json',
        'Accept-Language': 'ar',
        'crossDomain': 'true',
    }
    return headers
}