import React from "react";
import style from "./Spinner.scss";

const Spinner = () => {
  return (
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
