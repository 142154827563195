import React from 'react';
import ContentLoader from 'react-content-loader'

const HomepageLoader = () => {
  return (
    <ContentLoader 
      viewBox="0 0 500 300" 
      height="100vh" 
      width={"100%"} 
      backgroundColor="#ede3e3"
      foregroundColor="#e5dcc8"
    >
      <rect x="19" y="20" rx="0" ry="0" width="465" height="155" />

      <rect x="18" y="200" rx="0" ry="0" width="141" height="38" />
      <rect x="182" y="200" rx="0" ry="0" width="141" height="38" />
      <rect x="343" y="200" rx="0" ry="0" width="141" height="38" />
      <rect x="18" y="270" rx="0" ry="0" width="141" height="38" />
      <rect x="182" y="270" rx="0" ry="0" width="141" height="38" />
      <rect x="343" y="270" rx="0" ry="0" width="141" height="38" />
    </ContentLoader>
  )
}

export default HomepageLoader