import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import styles from "./MapOne.module.scss";
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';
import { getGeocode, getLatLng, } from "use-places-autocomplete";
import { Formik, Form, Field, useFormik } from 'formik';
import * as yup from "yup";
import { addAddress } from '../../services/address.service';
import { ADMIN } from '../../services/admin.service';
import { GetAddressDetails } from '../../redux/slice/addressSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cityList } from '../../redux/slice/getCities';
import {
  setDefaults,
  fromLatLng,
} from "react-geocode";


const containerStyle = {
  width: "100%",
  height: '400px',
  borderRadius: "10px"
};


// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp = /^([5-9]{1})([0-9]{9})$/
const saudiRegex = /^([5]{1})([0-9]{8})$/;

const MapOne = ({
  userDetails,
  laung,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const formik = useFormik();

  const libraries = useMemo(() => {
    return ["places"]
  }, []);


  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    // googleMapsApiKey: "AIzaSyASVBFaHUy7FSokcRlRUR1O5cezO4aZTec",
    googleMapsApiKey: "AIzaSyAwhW-oxoIPQo_lbiO5Izr7m2Sal6h51os",
  })

  setDefaults({
    // key: "AIzaSyASVBFaHUy7FSokcRlRUR1O5cezO4aZTec",
    key:"AIzaSyAwhW-oxoIPQo_lbiO5Izr7m2Sal6h51os"
  })

  const positionRef = useRef(null)
  const [map, setMap] = React.useState(null);
  const [current, setCurrent] = useState({ lat: 10.99835602, lng: 77.01502627 });
  const [defaultAddress, setDefault] = useState(false);
  const [countryCode, setCountryCode] = useState("+966");


  const availableCities = useSelector((state) => state?.cityList?.data?.response);
  const addAddressSchema = yup.object({
    firstName: yup
      .string()
      .required(t("first name is required")),
    lastName: yup
      .string()
      .required(t("last name is required")),
    mobileNumber: yup
      .string()
      .min(9, t("minimum number"))
      .max(10, t("max num"))
      .matches(countryCode == "+966" ? saudiRegex : phoneRegExp, t("phone number is not valid"))
      .required(t("mobile number is required")),
    pincode: yup
      .number()
      .min(5, t("pincode minimum")),
    address: yup
      .string()
      .required(t("address is required")),
    city: yup
      .string()
      .required(t("City is Required*")),

  })

  useEffect(() => {
    dispatch(cityList())
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrent({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [])

  const onLoad = useCallback(function callback(map) {
    setMap(map)
    const bounds = new window.google.maps.LatLngBounds(current);
    map.fitBounds(bounds);
  }, [current]);

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, []);

  const getCode = (formik) => {
    formik.setFieldValue("address", positionRef.current.value)
    getGeocode({ address: positionRef.current.value }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      setCurrent({ lat, lng })
    });
  };

  const handleGetAddress = (markerposition, formik) => {
    fromLatLng(markerposition.lat, markerposition.lng)
      .then(({ results }) => {
        const address = results[0].formatted_address;
        formik.setFieldValue("address", address)
      })
      .catch(console.error);
  }

  const handleDefaultAddress = (event) => {
    setDefault(!defaultAddress)
  };

  const resetRadioState = (event) => {
    setDefault(event.target.value)
  };


  const addressInitialValues = {
    firstName: userDetails?.first_name ? userDetails?.first_name : "",
    lastName: userDetails?.last_name ? userDetails?.last_name : "",
    mobileNumber: userDetails?.mobile_number ? userDetails?.mobile_number : "",
    pincode: "",
    landmark: "",
    address: "",
    city: userDetails?.city ? userDetails?.city : "",
  }


  const handleSubmitAddress = (values) => {
    getGeocode({ address: values.address }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      const data = {
        "first_name": values.firstName,
        "last_name": values.lastName,
        "address": values.address,
        "latitude": lat,
        "longitude": lng,
        "postal_code": values.pincode,
        "phone_number": values.mobileNumber,
        "landmark": values.landmark,
        "default_address": defaultAddress ? "1" : "0",
        "city_id": values.city,
        "country_code": countryCode
      };
      addAddress(data)
        .then(res => {
          if (res.data.status) {
            handleDefaultAddress()
            dispatch(GetAddressDetails())
            ADMIN.toast.success(res.data.response.message)
            return
          }
        })
        .catch(e => ADMIN.toast.error(e))
    })
  };


  return (
    <div className={styles.container} >
      {/* <div className={styles.one_map} >
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyASVBFaHUy7FSokcRlRUR1O5cezO4aZTec" }}
          defaultCenter={{
            lat: 10.99835602,
            lng: 77.01502627
          }}
          defaultZoom={11}
        />
      </div> */}
      <div className={styles.two_map} >
        {
          isLoaded ? (
            <div>

              <div className={styles.addres_inputs} >
                <Formik
                  initialValues={addressInitialValues}
                  validationSchema={addAddressSchema}
                  onSubmit={(values, { resetForm }) => {
                    resetForm()
                    handleSubmitAddress(values)
                  }}
                >
                  {
                    (formik) => {
                      return (
                        <Form>
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={current}
                            // center={{lat: 37.778519, lng: -122.405640}}
                            zoom={25}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            clickableIcons
                          >
                            {map && <Marker
                              position={current}
                              draggable={true}
                              onDragEnd={(e) => {
                                const newPosition = {
                                  lat: e.latLng.lat(),
                                  lng: e.latLng.lng(),
                                };
                                handleGetAddress(newPosition, formik)
                                setCurrent(newPosition);
                              }}
                            />}
                          </GoogleMap>
                          <div className={styles.inputs}>
                            <div style={{ width: "100%" }}>
                              <div className={styles.single_input} style={{ width: "100%" }}>
                                <Autocomplete
                                  onPlaceChanged={() => getCode(formik)}
                                  style={{ fontFamily: "Cairo-Regular", padding: "15px" }}
                                >
                                  <input
                                    className={styles.input}
                                    type='text'
                                    ref={positionRef}
                                    placeholder={t("search address")}
                                    onChange={formik.handleChange("address")}
                                    value={formik.values.address}
                                    // onBlur={formik.handleBlur("address")}
                                    style={{ fontFamily: "Cairo-Regular" }}
                                  />
                                </Autocomplete>
                                {(formik.errors.address && formik.touched.address) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.address}</span>}
                              </div>
                            </div>
                            <div className={styles.single_input} >
                              <input
                                id="firstName"
                                name="firstName"
                                onChange={formik.handleChange("firstName")}
                                value={formik.values.firstName}
                                className={styles.input}
                                type='text'
                                placeholder={t("Please enter the first name")}
                                onBlur={formik.handleBlur("firstName")}
                                style={{ fontFamily: "Cairo-Regular" }}
                              />
                              {(formik.errors.firstName && formik.touched.firstName) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.firstName}</span>}
                            </div>
                            <div className={styles.single_input} >
                              <input
                                id="lastName"
                                name="lastName"
                                onChange={formik.handleChange("lastName")}
                                value={formik.values.lastName}
                                className={styles.input}
                                type='text'
                                placeholder={t("Please enter the last name")}
                                onBlur={formik.handleBlur("lastName")}
                                style={{ fontFamily: "Cairo-Regular" }}
                              />
                              {(formik.errors.lastName && formik.touched.lastName) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.lastName}</span>}
                            </div>
                            <div className={styles.single_input} >
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                <select
                                  name="countrycode" id="countrycode" className={styles.select_option}
                                  value={countryCode}
                                  onChange={(e) => setCountryCode(e.target.value)}
                                >
                                  <option style={{ fontFamily: "Cairo-Regular" }} value="+91">India</option>
                                  <option style={{ fontFamily: "Cairo-Regular" }} value="+966">KSA</option>
                                </select>
                                <input
                                  maxLength={10}
                                  className={styles.input}
                                  type='text'
                                  id="mobileNumber"
                                  name="mobileNumber"
                                  onChange={formik.handleChange("mobileNumber")}
                                  value={formik.values.mobileNumber}
                                  placeholder={t("mobile number")}
                                  onBlur={formik.handleBlur("mobileNumber")}
                                  style={{ fontFamily: "Cairo-Regular" }}
                                />
                              </div>
                              {(formik.errors.mobileNumber && formik.touched.mobileNumber) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.mobileNumber}</span>}
                            </div>

                            <div className={styles.single_input} >
                              <input
                                className={styles.input}
                                type='text'
                                id="pincode"
                                name="pincode"
                                maxLength={6}
                                onChange={formik.handleChange("pincode")}
                                value={formik.values.pincode}
                                placeholder={t("pincode")}
                                onBlur={formik.handleBlur("pincode")}
                                style={{ fontFamily: "Cairo-Regular" }}
                              />
                              {(formik.errors.pincode && formik.touched.pincode) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.pincode}</span>}
                            </div>
                            <div className={styles.single_input} >
                              <input
                                name="landmark"
                                id="landmark"
                                value={formik.values.landmark}
                                onChange={formik.handleChange("landmark")}
                                onBlur={formik.handleBlur("landmark")}
                                className={styles.input}
                                type='text'
                                placeholder={t("landmark")}
                                style={{ fontFamily: "Cairo-Regular" }}
                              />
                            </div>
                            <div className={styles.single_input} >
                              {availableCities &&
                                <select
                                  className={styles.input}
                                  id="city"
                                  name="city"
                                  placeholder={t("select city")}
                                  value={formik.values.city}
                                  onChange={formik.handleChange("city")}
                                  onBlur={formik.handleBlur("city")}
                                  style={{ fontFamily: "Cairo-Regular" }}
                                >
                                  <option style={{ fontFamily: "Cairo-Regular" }} value="" disabled >{t("select city")}</option>
                                  {
                                    availableCities.map((dept, indi) => {
                                      return (
                                        <option key={indi} value={dept.id} style={{ fontFamily: "Cairo-Regular" }} >{dept.title}</option>
                                      )
                                    })
                                  }

                                </select>}
                              {(formik.errors.city && formik.touched.city) && <span className={styles.error_message} style={{ fontFamily: "Cairo-Regular" }} >{formik.errors.city}</span>}
                            </div>
                            <div className={styles.radio_input} >
                              <input
                                type="checkbox"
                                checked={defaultAddress}
                                onChange={(e) => handleDefaultAddress(e)}
                                style={{ height: "21px", width: "21px" }}
                              />
                              <p style={{ fontFamily: "Cairo-Regular" }}>{t("Make this address as default address")}</p>
                            </div>
                            {/* <div className={styles.radio_input} >
                              <input
                                value="0"
                                type="radio"
                                checked={defaultAddress === "0"}
                                onChange={(e) => resetRadioState(e)}
                                style={{ height: "21px", width: "21px" }}
                              />
                              <p style={{ fontFamily: "Cairo-Regular" }}>{t("no")}</p>
                            </div> */}
                          </div>
                          {/* <div className={styles.single_input} >
                            <input type='submit' className={styles.input} />
                          </div> */}
                          <button type='submit' style={{ fontFamily: "Cairo-Regular" }} >{t("add address")}</button>
                        </Form>
                      )
                    }
                  }
                </Formik>
              </div>
            </div>
          ) : <></>
        }
      </div>
    </div>
    // AIzaSyASVBFaHUy7FSokcRlRUR1O5cezO4aZTec
  )
}

export default MapOne;

