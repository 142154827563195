import React from 'react';
import styles from "./HomeEssentials.module.scss";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductList } from '../../../../redux/slice/productListSlice';

const HomeEssentials = ({ sectionFive, laung }) => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch()
    const { catID, departmentID } = params;
    const handleNaviagte = (data) => {
        navigate(`all_products/${data.master_id}/${1}`)
    };

    const departmentDetails = useSelector((state) => state?.departMentDetails?.data?.response);
    return (
        <div className={styles.container} >
            <p className={styles.heading} >
                {departmentDetails.map((depart, indi) => {
                    return (
                        departmentID == depart.id && <span style={{fontFamily : laung == "ar" && "Cairo-Semibold"}} key={depart.id}>{depart.title}</span>
                    )
                })}
            </p >
            <div className={styles.overlay_home} >
                <div className={styles.home_ess}
                    // style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}
                         >
                    {
                        sectionFive.map((data, ind) => {
                            return (
                                < div className={styles.product_card} key={ind} onClick={() => handleNaviagte(data)} >
                                    <img src={data.image}
                                        alt={data.title}
                                        title={data.title}
                                        className={styles.essential_category}
                                        // style={{ height: "420px", width: "100%", objectFit: "cover", borderRadius: "8px" }}
                                    />
                                    <p className={styles.product_category} >{data.title}</p>
                                    <p className={styles.product_types} >{data.sub_title}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div> { /* <div className={styles.flag} ></div> */}
        </div>
    )
}

export default HomeEssentials