import { configureStore } from "@reduxjs/toolkit";
import HomePageDetailsReducer from "./slice/departmentSlice";
import KartDetailsReducer from "./slice/cartSlice";
import WishListdetailsReducer from "./slice/wishListSlice";
import AllOrderDetailsReducer from "./slice/allOrderSlice";
import ProductListReducer from "./slice/productListSlice";
import AllAddressReducer from "./slice/addressSlice";
import FilterDetails from "./slice/filterSilce";
import DepartmentDetails from "./slice/departMentSliceTwo";
import ProductDetails  from "./slice/ProductDetailsSlice";
import ChatListReducer from "./slice/ordersChatListSlcie";
import OrderChatDetails from "./slice/SingleOrderChat"
import Menucounts from "./slice/menuCountSlice";
import VendorList from "./slice/venderListSlice";
import CityList from "./slice/getCities";
import spCategoreis from "./slice/spCaterories";
import TremsPrivacy from "./slice/termsPrivacyPolicy";
import SearchProductList from "./slice/searchproductListing";
import ratingQuestions from "./slice/rattingDetailsSlice";
import productRattings from "./slice/getProductListSlice";
import pageSeoDetails from "./slice/seodetailsSlice"
 
export const store = configureStore({
  reducer:{
    searchProductList : SearchProductList,
    homePageDetails : HomePageDetailsReducer,
    cartDetails : KartDetailsReducer,
    wishListDetails : WishListdetailsReducer,
    productList : ProductListReducer,
    allOrderDetails : AllOrderDetailsReducer,
    allAddressDetails : AllAddressReducer,
    filterDetails : FilterDetails,
    departMentDetails : DepartmentDetails,
    productDetails :ProductDetails,
    chatListDetails : ChatListReducer,
    orderChatDetails: OrderChatDetails,
    menuCount: Menucounts,
    vendorList : VendorList,
    cityList: CityList,
    spcategories:spCategoreis,
    termsandPrivacy:TremsPrivacy,
    RatingQuestions:ratingQuestions,
    productRattings:productRattings,
    pageSeoDetails:pageSeoDetails,
  },
})