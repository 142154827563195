import React from "react";
import styles from "./VendorHeading.module.scss";

const laung = localStorage.getItem("laung");

export const H1 = (props) => {
  const laung = localStorage.getItem("laung");
  return (
    <h1
      style={props.style}
      className={laung == "ar" ? styles.ar_heading : styles.heading}
    >
      {props.children}
    </h1>
  )
}

export const H3 = (props) => {
  return(
    <h3
      style={props.style}
      className={laung == "ar" ? styles.ar_subheading : styles.subheading}
    >
      {props.children}
    </h3>
  )
};

export const P = (props) => {
  return (
    <p
      style={props.style}
      className={laung == "ar" ? styles.ar_para_text : styles.para_text}
    >
      {props.children}
    </p>
  )
}