import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getKartDetails = createAsyncThunk("getKartDetails",  async () => {
  const response = await axiosInstance.get("get_cart")
  return response.data;
});

const getKartDetailsSlice = createSlice({
name:"KartDetails",
  initialState: {
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(getKartDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getKartDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getKartDetails.rejected, (state, action) => {
      state.isError = true;
    })
  }
});

export default getKartDetailsSlice.reducer;