import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDj8u3IFztXhXM6JG6LEoPg59qcjIGk2Ig",
  authDomain: "venderlane-ca0f9.firebaseapp.com",
  projectId: "venderlane-ca0f9",
  storageBucket: "venderlane-ca0f9.appspot.com",
  messagingSenderId: "555648636016",
  appId: "1:555648636016:web:effb00b85e275c777050b5",
  measurementId: "G-VNL2294WPH"
};

export const fireStore = initializeApp(firebaseConfig);
