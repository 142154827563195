import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import Rating from '@mui/material/Rating';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import styles from "./RattingModal.module.scss";
import { fetchProductList } from '../../../redux/slice/productListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { submitRatting } from '../../../redux/actions/ratting';
import { orderProductRatting } from '../../../services/Order.service';
import { useParams } from 'react-router-dom';
import { ADMIN } from '../../../services/admin.service';
import { useTranslation } from 'react-i18next';
import Rating from 'react-rating';
import ActiveStar from "../../../assets/Images/svgs/active_star.svg";
import InActiveStar from "../../../assets/Images/svgs/inactive_star.svg";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // height: "60%",
  width: "40%",
  bgcolor: '#EDE3CA',
  // border: '2px solid #000',
  boxShadow: 24,
  // display:"flex",
  // flexdirection:"column",
  p: 2,
};

const RattingModal = ({
  productDetail,
  handleGetOrderDetails,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { ovid } = useParams()
  const laung = (localStorage.getItem('laung'));

  const rattingQuestions = useSelector((state) => state?.RatingQuestions?.data?.response);

  const [open, setOpen] = useState(false);
  const [orderRating, setOrderOrderRating] = useState(0);
  const [comment, setComment] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setOrderOrderRating(0)
  };

  const handleSubmitComment = () => {
    const data = {
      "order_detail_id": productDetail.order_detail_id,
      "rating": orderRating,
      "comment": comment
    }
    orderProductRatting(data).then(res => {
      if (res.data.status) {
        ADMIN.toast.success(res.data.response.message)
        handleGetOrderDetails(ovid)
      }
    })
      .catch(e => {
        ADMIN.toast.error(e)
      })
    handleClose();
  };

  return (
    <div style={{height:"50%"}} >
      <Button onClick={handleOpen} style={{ color: "#2A2821", fontFamily: "Cairo-Semibold" }}>{t("rate us")}</Button>
      <Modal
        open={open}
        style={{ border: "none" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.modal_body} >
            <h1 className={styles.heading} style={{ fontFamily: "Cairo-Bold" }} >
              {t("rate yor experience")}
            </h1>
            <img src={productDetail.image} alt="product" className={styles.product_pic} />
            <p style={{ fontFamily: "Cairo-Semibold" }} className={styles.product_name} >{productDetail.product_name}</p>
            {/* <Rating
              value={orderRating}
              size="large"
              precision={0.5}
              onChange={(event, newValue) => {
                setOrderOrderRating(newValue);
              }}
            /> */}
            <Rating
              initialRating={orderRating}
              // fractions={0.5}
              precision={0.5}
              onChange={(event, newValue) => {
                setOrderOrderRating(event);
              }}
              direction={laung == "ar" ? "rtl" : "ltr"}
              emptySymbol={<img src={InActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
              fullSymbol={<img src={ActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
            />

            <TextField
              id="filled-multiline-static"
              label={t("enter comments")}
              multiline
              rows={6}
              defaultValue={comment}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ width: "70%", marginTop: "1rem", fontFamily: "Cairo-Semibold" }}
            />
            <button style={{ marginTop: "2rem", fontFamily: "Cairo-Semibold" }}
              onClick={() => handleSubmitComment()}
            >
              {t("submit feedback")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
};

export default RattingModal;
