import React from 'react';
import styles from "./Nodata.module.scss";
import NoInfo from "../assets/Images/svgs/Nodata.svg";
import BannerFlower from "../assets/Images/pngs/banner_flower.png";
import Flower from "../assets/Images/svgs/flower.svg";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Nodata = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const laung = localStorage.getItem("laung")
    const goBack = () => {
        if (window.history.state === null || window.history.state.url === '_black' || window.history.state.idx == 0) {
            navigate(laung == 'en' ? `/${laung}` : "/");
        } else {
           navigate(laung == 'en' ? `/${laung}` : "/");
        }
    }


    return (
        <div className={styles.container} >
            <img src={NoInfo} alt="" />
            <p className={styles.heading} style={{ fontFamily: laung == "ar" ? "Cairo-Bold" : "avirain_mediam" }} >{t("items not found")}</p>
            <p className={styles.sub_heading} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "Avenir_Book" }} >{t("data not available")}</p>
            <button className={styles.go_back} onClick={() => goBack()} style={{ fontFamily: laung == "ar" ? "Cairo-Semibold" : "avirain_mediam" }} >{t("go back")}</button>
            < img src={BannerFlower} className={styles.flower_one} alt="Flower" />
            <img src={Flower} className={styles.flower_two} alt="Flower" />
        </div>
    )
}

export default Nodata;  