import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import fav_icon from '../../logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearSpecificStateValue, seoPageDetails } from '../redux/slice/seodetailsSlice';

const HelmetAllInOne = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [values, setValues] = useState(null);
    const pageSeoDetails = useSelector((state) => state.pageSeoDetails.data?.response);

    useEffect(() => {
        let key;
        if (location.pathname.includes('/p/')) {
            key = 'productssss';
        }

        else if (location.pathname.includes('/b/')) {
            key = 'blogsssss';
        }
        else if (location.pathname.includes('*')) {
            key = 'not found';
        } else {
            switch (location.pathname) {
                case '/':
                    key = 'landing';
                    break;
                case '/about_us':
                    key = 'about';
                    break;
                case '/my_cart':
                    key = 'cart';
                    break;
                case '/account/saved_address':
                    key = 'address';
                    break;
                case '/account/chats':
                    key = 'chats';
                    break;
                case '/faqs':
                    key = 'faq';
                    break;
                case '/login':
                    key = 'login';
                    break;
                case '/account':
                    key = 'orders';
                    break;
                case '/privacy_policy':
                    key = 'privacy';
                    break;
                case '/account/my_profile':
                    key = 'profile';
                    break;
                case '/vendor_register':
                    key = 'register';
                    break;
                case '/our_story':
                    key = 'story';
                    break;
                case '/terms_conditions':
                    key = 'terms';
                    break;
                case '/wish_list':
                    key = 'wishlist';
                    break;
                case '/blogs':
                    key = 'blog';
                    break;
                default:
                    key = 'not_found';
                    break;
            }
        }
        dispatch(clearSpecificStateValue());
        dispatch(seoPageDetails(key))
            .then((res) => {
                if (res?.payload?.response) {
                    setValues(res.payload.response);
                    document.title = res.payload.response?.meta_title;
                    updateMetaTag('description', res.payload.response?.meta_description);
                    updateCanonicalLink(`https://www.venderlane.com/${res.payload.response.canonical ? res.payload.response.canonical : ""}`);
                } else {
                }
            })
            .catch((error) => {
            });
    }, [dispatch, location.pathname]);

    const updateMetaTag = (name, content) => {
        const metaTag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
        if (metaTag) {
            metaTag.setAttribute('content', content);
        } else {
            const newMetaTag = document.createElement('meta');
            newMetaTag.setAttribute(name.startsWith('og:') ? 'property' : 'name', name);
            newMetaTag.setAttribute('content', content);
            document.head.appendChild(newMetaTag);
        }
    };

    const updateCanonicalLink = (url) => {
        let canonicalLink = document.querySelector('link[rel="canonical"]');
        if (!canonicalLink) {
            canonicalLink = document.createElement('link');
            canonicalLink.setAttribute('rel', 'canonical');
            document.head.appendChild(canonicalLink);
        }
        canonicalLink.setAttribute('href', url);
    };

    if (!pageSeoDetails) {
        return null;
    }

    const { meta_title, meta_description, h1, url, canonical, image, h2 } = pageSeoDetails;
    const author = 'Venderlane Author';
    const robots = 'Vendelane index, Vendelane follow';
    const canonicalUrl = canonical ? `https://www.venderlane.com/${canonical}` : 'https://www.venderlane.com/';


    return (
        <>
            <Helmet>
                <title>{meta_title}</title>
                <link rel='icon' href={fav_icon} />
                <meta name="title" content={meta_title} />
                <meta name='description' content={meta_description} />
                <meta name='author' content={author} />
                <meta name='robots' content={robots} />
                <meta property='og:url' content={url} />
                <link rel='canonical' href={canonicalUrl} />
                <link rel='alternate' hreflang='en-SA' href={`https://www.venderlane.com/en/${url}`} />
                <link rel='alternate' hreflang='ar-SA' href={`https://www.venderlane.com/${url}`} />
                <link rel='alternate' hreflang='x-default' href={`https://www.venderlane.com/${url}`} />
                <meta property='og:title' content={meta_title} />
                <meta property='og:description' content={meta_description} />
                <meta property='og:image' content={image} />
                <meta property='og:url' content={`https://www.venderlane.com/${url}`} />
                <meta property='og:type' content='website' />
                <meta property='og:site_name' content='Venderlane' />
                <meta property='og:locale' content='en-SA' />
            </Helmet>
            <h1 className='seo' >{h1}</h1>
            <h2 className='seo' >{h2}</h2>
        </>
    );
}

export default HelmetAllInOne;