import React from 'react';
import ContentLoader from 'react-content-loader'

const CatelogMagicLoaders = () => {
  return (

    <ContentLoader
      viewBox="0 0 500 300"
      height="100%"
      width={"75%"}
      backgroundColor="#f9f6f6"
      foregroundColor="#f9f6f6"
    >
      <rect x="20" y="20" rx="0" ry="0" width="150" height="20" />
      <rect x="380" y="20" rx="0" ry="0" width="100" height="16" />
      <rect x="20" y="60" rx="0" ry="0" width="100" height="110" />
      <rect x="140" y="60" rx="0" ry="0" width="100" height="110" />
      <rect x="260" y="60" rx="0" ry="0" width="100" height="110" />
      <rect x="380" y="60" rx="0" ry="0" width="100" height="110" />
      <rect x="20" y="180" rx="0" ry="0" width="100" height="110" />
      <rect x="140" y="180" rx="0" ry="0" width="100" height="110" />
      <rect x="260" y="180" rx="0" ry="0" width="100" height="110" />
      <rect x="380" y="180" rx="0" ry="0" width="100" height="110" />

    </ContentLoader>
  )
}

export default CatelogMagicLoaders