import React from 'react';
import styles from "./SellerCategoriesSwipper.module.scss";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay, FreeMode } from 'swiper/modules';


function moveSelectedObjectAtBeginning(arr, selectedId) {
  let mappedArray = arr.map((obj) => {
    return { ...obj };
  });

  const index = mappedArray.findIndex((obj) => Number(obj.id) == selectedId);
  if (index !== -1) {
    const selectedObj = mappedArray.splice(index, 1)[0];
    mappedArray.unshift(selectedObj);
    return mappedArray;
  }
}
const SellerCategoriesSwipper = ({ 
  selectedFilter, 
  setSelectedFilter,
  categories,
}) => {

  const laung = localStorage.getItem("laung");
  const SPID = localStorage.getItem("spCategory");



  const handleSelectFilter = (subcat) => {
    if (subcat.id != SPID) {
      localStorage.setItem("spCategory", subcat.id)
    }
    if (selectedFilter === subcat.id) {
      setSelectedFilter("")
      localStorage.removeItem("spCategory")
    } else {
      setSelectedFilter(subcat.id)
    }
  };

  const RearrangedCategroies = moveSelectedObjectAtBeginning(categories, SPID ?? "1");

  return (
    <div className={styles.container}>
      <div className={styles.header_left}>
        <Swiper
          className={laung === "ar" ? "arabic_vers" : "eng_version"}
          dir={laung === "ar" ? "rtl" : "ltr"}
          // navigation={true}
          loop={true}
          pagination={false}
          modules={[FreeMode, Pagination, Autoplay, Navigation]}
          spaceBetween={0}
          slidesPerView={4}
          // freeMode={true}
          // onSwiper={(swiper) => {
          //   swiper.$wrapperEl[0].style.transitionTimingFunction = "linear";}}
          speed={15000}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
            autoplayDisableOnInteraction: false,
          }}
        >

          {
            categories && categories?.map((cat, indi) => {
              return (
                <SwiperSlide key={indi} onClick={() => handleSelectFilter(cat)}>
                  <div className={styles.category}  style={{ border: selectedFilter === cat.id ? "2px solid #2A2821" : " 2px solid #D9CBA0" }} key={indi} >
                    <img src={cat.image} alt={cat.title} title={cat.title} />
                    <p style={{ fontFamily: laung == "ar" && "Cairo-Semibold" }} >{cat?.title}</p>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </div>
  )
}

export default SellerCategoriesSwipper