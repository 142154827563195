import React, { useEffect, useState } from 'react';
import styles from "./DepartmentTabwithCategoryList.module.scss"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import chevron_right from "../../../assets/Images/svgs/chevron-right.svg";

const DepartmentTabwithCategoryList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const laung = (localStorage.getItem('laung'));

  const categoryname = localStorage.getItem("categoryname");
  const departmentID = localStorage.getItem("departmentID");
  const [deptID, setDeptId] = useState(departmentID)

  const [categorylist, setCategorylist] = useState([]);

  const departmentDetails = useSelector((state) => state?.departMentDetails?.data?.response);

  useEffect(() => {
    if (departmentDetails && departmentID) {
      const categorydata = departmentDetails
        .filter((item) => item.id == departmentID)
        .map((item) => item.category);
      setCategorylist(categorydata);
    }
  }, [departmentID, departmentDetails, deptID]);

  const handleCategories = (dept) => {
    setDeptId(dept.id)
    localStorage.setItem("categoryname", dept.url);
    localStorage.setItem("departmentID", dept.id);
    localStorage.setItem("department_title", dept.title);
  };

  const handleNavigate222 = (category) => {
    localStorage.setItem("fromsearch", false);
    localStorage.setItem("searchTerm", '')

    const newUrl = `/category/${categoryname}/${category.url}`;
    const newUrlEn = `/${laung}/category/${categoryname}/${category.url}`;

    window.open(laung == "en" ? newUrlEn : newUrl, );


  };

  return (
    <div className={laung == "ar" ? styles.ar_container : styles.container} >
      <div className={styles.catergories_navbar} >
        <div className={styles.back_color}>
          <div className={styles.sidebar_head}>
            <button onClick={() => navigate( laung == "en" ? `/${laung}` : '/')}><img src={chevron_right} alt="" />{t("back")}</button>
          </div>
          <ul className={styles.categories} >
            {Array.isArray(departmentDetails) && departmentDetails.map((department, indi) => {
              return (
                <li
                  key={indi}
                  className={styles.category_name}
                  onClick={() => handleCategories(department)}
                  style={{
                    borderBottom: department.id == departmentID ? "3px solid #D5C79D" : "none",
                    fontSize: department.id == departmentID ? "14px" : "12px",
                    color: department.id == departmentID ? "#D5C79D" : "#878472"
                  }}
                >
                  <a>{department.title}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {categorylist.length > 0 && (
        <div className={styles.categories_content}>
          <h6>{t('Categories')}</h6>
          {categorylist[0]?.map((item, index) => (
            <div key={index}>
              <p onClick={() => handleNavigate222(item)}><img src={item?.image} />{item?.title}</p>
            </div>
          ))}
          <div className={styles.empty}></div>
        </div>
      )}
    </div>
  )
}

export default DepartmentTabwithCategoryList;
