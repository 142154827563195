import React from 'react';
import ReactPaginate from 'react-paginate';
import styles from './Pagination.module.scss';
import leftArrow from '../../assets/scss/left.svg';
import rightArrow from '../../assets/scss/right.svg';

function Pagination({ 
  pageNumber, 
  totalitemcounts, 
  setPageNumber, 
  setData,
  setProductLoader 
}) {
  const laung = localStorage.getItem("laung");

  const pageCount = Math.ceil(totalitemcounts / 40);

  const handlePageClick = (event) => {
    // setData([])
    setProductLoader(true)
    setPageNumber(event.selected)
  };

  return (
    <div className={styles['pagination-container']}>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<img src={laung == 'ar' ? leftArrow : rightArrow} alt="Next" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<img src={laung == 'ar' ? rightArrow : leftArrow} alt="Previous" />}
        marginPagesDisplayed={2}
        containerClassName="pagination"
        activeClassName={styles.active}
        initialPage={pageNumber}
        forcePage={pageNumber}
      />
    </div>
  );
}

export default Pagination;
