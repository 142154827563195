import React from 'react';
import styles from "./Carousel.module.scss";
import Swipper from './Swipper';


const CarouselBanner = ({ bannerData }) => {

  return (<
    div className={styles.container} >
      <Swipper bannerData={bannerData}/>
  </div >

  )
}

export default CarouselBanner;