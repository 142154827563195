import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getWishListDetails = createAsyncThunk("getWishListDetails", async (page, length) => {
  const response = await axiosInstance.get(`wishlist?index=${page}&size=${10}`)
  return response.data;
});


const getWishListDetailsSlice = createSlice({
  name:"WishLisstDetails",
  initialState:{
    isLoading: false,
    data : null,
    isError : false
  },

  extraReducers : (builder) => {
    builder.addCase(getWishListDetails.pending, (state, action) => {
      state.isLoading  = true;
    });

    builder.addCase(getWishListDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getWishListDetails.rejected, (state, action ) => {
      state.isError = true;
    })
  }
});

export default getWishListDetailsSlice.reducer;