import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getAllSpCategories = createAsyncThunk("getSpCategories", async (laung) => {
  const response  = await axiosInstance.get(`sp_department?laung=${laung}`)
  return response.data
});

const spCategoriceSlice = createSlice({
  name:"SpCAtegoeries",
  initialState:{
    isLoading:false,
    data:null,
    isError:false
  },

  extraReducers: (builder) => {
    builder.addCase(getAllSpCategories.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAllSpCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data  = action.payload;
    });

    builder.addCase(getAllSpCategories.rejected, (state, action) => {
      state.isError = true;
    })
  }
});


export default spCategoriceSlice.reducer;