import React, { useEffect, useState } from 'react';
import styles from "./faqs.module.scss";
import { H1, H3, P } from '../../constants/CustomText/VendorHeading';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFaqs } from '../../services/Faq.service';
import Grid from '@mui/material/Unstable_Grid2';


const FAQ = () => {
  const laung = (localStorage.getItem('laung'));
  const [queAns, setQueAns] = useState([]);
  const [firstOpen, setFirstOpen] = useState("0")

  useEffect(() => {
    getFaqs()
      .then(res => {
        if (res.data.status) {
          setQueAns(res.data.response)
        }
      })
      .catch(err => {
        console.error(err.message, "faq error")
      })
  }, []);

  const hanldeAccordian = (fid) => {
    if (firstOpen != fid) {
      setFirstOpen(fid)
    } else {
      setFirstOpen("")
    }
  }



  return (
    <div className={styles.container}>
      <div className={styles.faqs_body} >
        <Grid container spacing={2} >
          {queAns.length != 0 && queAns.map((data, indi) => {
            return (
              <Grid xs={12} lg={12} key={data.id} >
                  <Accordion
                    key={data.id}
                    expanded={indi == firstOpen}
                    style={{ backgroundColor: "#f4efe2", marginTop: "20px" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize='large' sx={{ color: "#2A2821" }} />}
                      onClick={() => hanldeAccordian(indi)}
                    >
                      <H3>Q. {data.question}</H3>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div dangerouslySetInnerHTML={{ __html: data.answer }}  style={{fontFamily: laung == "ar" ? "Cairo-Regular" : "avirain_mediam", wordBreak:"break-word"}} />
                    </AccordionDetails>
                  </Accordion>

              </Grid>

            )
          })}
        </Grid>

      </div>
    
    </div>
  )
}

export default FAQ;