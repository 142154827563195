import axiosInstance from "../../services/api";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const seoPageDetails = createAsyncThunk("seoDetails", async (page) => {
  const response = await axiosInstance.get(`seo?page=${page}`)
  if (response.data.response == ('' || "")) {
    return null
  }
  return response.data;
});

export const clearSpecificStateValue = createAction('clear/data');

const getPageSeoDetailsSlice = createSlice({
  name: 'PageSeoDetails',
  initialState: {
    isLoading: false,
    data: null,
    isError: false
  },

  extraReducers: (builder) => {
    builder.addCase(seoPageDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(seoPageDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(seoPageDetails.rejected, (state, action) => {
      state.isError = true
    })

    builder.addCase(clearSpecificStateValue, (state) => {
      state.data = null;
    });
  }
})


export default getPageSeoDetailsSlice.reducer;