import React from 'react';
import ContentLoader from 'react-content-loader'

const DummyLoader = () => {
    return (
        <ContentLoader
            viewBox="0 0 500 600"
            height="100%"
            width={"100%"}
            backgroundColor="#f9f6f6"
            foregroundColor="#f9f6f6"
        >
            <rect x="20" y="30" rx="0" ry="0" width="43%" height="250" />
            <rect x="270" y="30" rx="0" ry="0" width="43%" height="250" />
            <rect x="20" y="300" rx="0" ry="0" width="43%" height="250" />
            <rect x="270" y="300" rx="0" ry="0" width="43%" height="250" />
        </ContentLoader>
    )
}

export default DummyLoader