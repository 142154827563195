import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProductRatings = createAsyncThunk("productRating", async(pid) => {
  const response = await axiosInstance.get(`productRating?product_id=${pid.pid}&rating=${pid.rating}&index=0&size=10`);
  return response.data;
});

const productRattignDetails = createSlice({
  name:"ProductList",
  initialState: {
    isLoading:false,
    data : null,
    isError : false
  },
  extraReducers: (builder) => {
    builder.addCase(getProductRatings.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getProductRatings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getProductRatings.rejected, (state, action) => {
      state.isError = true
    })
  }
})

export default productRattignDetails.reducer