import axiosInstance from "./api/index";

export const checkMobile = async (data) => {
  try {
    const response  = await axiosInstance.post("check_mobile", data)
    return response
  } catch (error){
    throw(error)
  }
};

export const sendOTP = async (data) => {
  try {
    const response  = await axiosInstance.post("send_otp", data)
    return response
  } catch (error){
    throw(error)
  }
};

export const loginVerifyOTP = async (data) => {
  try {
    const response  = await axiosInstance.post("login_verify_otp", data)
    return response
  } catch (error){
    throw(error)
  }
};

export const onlyVerifyOTP = async (data) => {
  try {
    const response  = await axiosInstance.post("verify_otp", data)
    return response
  } catch (error){
    throw(error)
  }
};

export const registerUser = async (data) => {
  try {
    const response = axiosInstance.post("register", data)
    return response
  } catch (error){
    throw(error)
  }
}

export const registerVerifyOTP = async (data) => {
  try {
    const response = axiosInstance.post("register_verify_otp", data)
    return response
  } catch (error){
    throw(error)
  }
}



export const loginWithPassword = async (data) => {
  try {
    const response = await axiosInstance.post("login", data)
    return response
  } catch (error) {
    throw(error)
  }
}

export const logOutUser = async () => {
  try {
    const response = await axiosInstance.get("logout")
    return response
  } catch (error) {
    throw(error)
  }
};

export const getUserDetails = async () => {
  try {
    const response = await axiosInstance.get("profile") 
    return response
  } catch (error){
    throw(error)
  }
};

export const upDateUserDetails = async (data) => {
  try {
    const response = await axiosInstance.post("update_profile", data);
    return response;
  } catch(error){
    throw(error)
  }
}

export const changePassword = async (data) => {
  try {
    const response = axiosInstance.post("change_password", data)
    return response
  } catch (error){
    throw(error)
  }
}

export const updateMobileNumber = async (data) => {
  try {
    const response = axiosInstance.post("update_mobile_number", data)
    return response
  } catch (error){
    throw(error)
  }
}

export const pendingregisterUser = async (data) => {
  try {
    const response = axiosInstance.post("pending_register", data)
    return response
  } catch (error){
    throw(error)
  }
}