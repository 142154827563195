import axiosInstance from "./api/index";

export const getLandingPageDetails = async () => {
  try {
    const response = await axiosInstance.get("landing_page")
    return response
  } catch (error){
    return error
  }
}

export const getDepartments = async () => {
  try {
    const response = await axiosInstance.get("department")
    return response
  } catch (error) {
    console.error(error, "department api error")
  }
}

