import { createSlice, createAsyncThunk } from "@reduxjs/toolkit/dist";
import axiosInstance from "../../services/api";

export const getChatList = createAsyncThunk("chatList", async (index, size) =>{
  const response = await axiosInstance.get(`get_chat?index=${index}&size=${30}`)
  return response.data
});

const orderChatListDetails = createSlice({
  name:"orderchatDetails",
  initialState:{
    isLoading:false,
    data:null,
    isError : false,
  },

  extraReducers: (builder) => {
    builder.addCase(getChatList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getChatList.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getChatList.rejected, (state, action) => {
      state.isError = true;
    })
  }
})

export default orderChatListDetails.reducer;