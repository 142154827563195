import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from "./Sidebar.module.scss";
import swal from "sweetalert";
import { getUserDetails } from '../../../services/Profile';
import { useTranslation } from 'react-i18next';


const Sidebar = ({
  closeDrawer,
  setDrawer,
  laung
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    getUserDetails()
      .then(res => {
        if (res.data.status) {
          setUserDetails(res.data.response)
        }
      })
      .catch(e => {
        if (e.response.status == "503") {
          navigate(laung == 'en' ? `/${laung}/login` : "/login")
        }
      })
  }, []);

  const handleLogOut = () => {
    swal({
      title: t("are you sure"),
      text: t("logout from venderlane"),
      icon: "warning",
      buttons: [t("no"), t("yes")]
    }).then(confirm => {
      if (confirm) {
        let keysToRemove = ["venlogtoken", "filteredCities", "filteredSubCategories", "filteredBrands", "filterSortingID", "filterCustomerReview", "searchTerm", "minimum_price", "maximum_price", "browse_vendor"];
        keysToRemove.forEach(k => localStorage.removeItem(k))
        navigate(laung == 'en' ? `/${laung}/login` : "/login")
      }
    })
  };

  const navigateToOrders = () => {
    // setDrawer(false)
    navigate(laung == "en" ? `/${laung}/account` : "/account")
  };

  return (
    <div className={styles.sidebar} >
      <div className={styles.sidebar_category} >
        <p className={styles.category_name} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("my account")}</p>
        {userDetails && <p className={styles.category_sub} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("hello")}, {userDetails.first_name}</p>}
      </div>
      <div className={styles.order_category} >
        <p className={styles.orders_label} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("orders")}</p>
        <p className={styles.order_returns} onClick={() => (navigateToOrders(), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("all orders")}</p>
        <p className={styles.my_chats} onClick={() => (navigate("chats"), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("orders chat")}</p>
      </div>
      {/* <div className={styles.update_category} >
        <p className={styles.update_label} >UPDATES</p>
        <p className={styles.notifications} >Notification</p>
      </div> */}
      <div className={styles.account_category} >
        <p className={styles.account_label} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("account")}</p>
        <p className={styles.acc_sub_cat} onClick={() => (navigate("my_profile"), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("my profile")}</p>
        {/* <p className={styles.acc_sub_cat} >Saved Cards</p> */}
        <p className={styles.acc_sub_cat} onClick={() => (navigate("saved_address"), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{t("saved address")}</p>
      </div>
      <div className={styles.order_category} >
        <p className={styles.orders_label} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }}>{t("legal")}</p>
        <p className={styles.order_returns}
          onClick={() => (navigate(laung == "en" ? `/${laung}/terms_conditions` : "/terms_conditions"), closeDrawer())}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("terms & conditions")}
        </p>
        <p className={styles.my_chats}
          onClick={() => (navigate(laung == "en" ? `/${laung}/privacy_policy` : "/privacy_policy"), closeDrawer())}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("privacy policy")}
        </p>
        <p className={styles.my_chats}
          onClick={() => (navigate(laung == "en" ? `/${laung}/faqs`:"/faqs"), closeDrawer())}
          style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >
          {t("faq")}
        </p>
        <p className={styles.my_chats} onClick={() => (handleLogOut(), closeDrawer())} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("logout")}</p>
      </div>
    </div>
  )
};

export default Sidebar;
