import React from 'react';
import styles from "./ThankYou.module.scss";
import Success from "../../../../assets/Images/svgs/success.svg";
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ThankYou = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { departmentID } = params;
  const {t} = useTranslation();
  const laung = localStorage.getItem("laung");

  const handleNavigate = () => {
    navigate(laung == 'en' ? `/${laung}` : "/")
  };
  
  return (
    <div className={styles.container} >
      <img src={Success} alt ='' style={{height:"126px", width:"126px"}} />
      <p className={styles.heading} >{t("order request submitted")}</p>
      <p className={styles.message} >{t("request underprocess")}</p>
      <p className={styles.go_home} onClick={() => handleNavigate()} >{t("go home")}</p>
    </div>
  )
}

export default ThankYou;