import { createSlice, createAsyncThunk } from "@reduxjs/toolkit/dist";
import axiosInstance from "../../services/api";


export const menuCounts = createAsyncThunk("headerCount", async () => {
  const response = await axiosInstance.get("menu")
  return response.data
});

const menuCountSlice = createSlice({
  name:"MenuCounts",
  initialState: {
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(menuCounts.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(menuCounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(menuCounts.rejected, (state, action) => {
      state.isError = true;
    })
  }
});


export default menuCountSlice.reducer;

