import axiosInstance from "../../services/api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const gettingRatingQuestions = createAsyncThunk("VendorRating", async () => {
  const resposne = await axiosInstance.get("vendorRating")
  return resposne.data
});


const ratingQuestionsSlice = createSlice({
  name:"ratingQuestionDetails",
  initialState: {
    isLoading:false,
    data:null,
    isError:false,
  },
  extraReducers: (builder) => {
    builder.addCase(gettingRatingQuestions.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(gettingRatingQuestions.fulfilled, (state, action)=>{
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(gettingRatingQuestions.rejected, (state, action) => {
      state.isError = true;
    })
  }
});

export default ratingQuestionsSlice.reducer;

// https://api.venderlane.com/web/v0_1/api/vendorRating