import React from 'react'
import "./ProductLoader.css"
const ProductLoader = () => {
  return (
    <div className="loader-container">
      {/* <div className="spinner"></div> */}
      <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default ProductLoader
