import React, { useEffect, useState } from 'react'
import styles from "./Orders.module.scss";
import ChevLeft from "../../../assets/Images/svgs/chevrin_left.svg";
import ChevRight from "../../../assets/Images/svgs/chevRight.svg";
import OrderStatus from "../../../assets/Images/svgs/orderstatus.svg";
import PDF from "../../../assets/Images/svgs/pdf.svg";
import Download from "../../../assets/Images/svgs/download.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderDetails, updateOrderStatus, trackOrderShipment } from '../../../services/Order.service';
import { ADMIN } from '../../../services/admin.service';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Dialog from '@mui/material/Dialog';
import ChatModal from '../chat/ChatModal';
import { singelOrderChatDetails } from '../../../redux/slice/SingleOrderChat';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Rating from '@mui/material/Rating';
import RattingModal from './RattingModal';
import { gettingRatingQuestions } from '../../../redux/slice/rattingDetailsSlice';
import VendorRatingModal from './VendorRatingModal';
import { H3, P } from '../../../constants/CustomText/VendorHeading';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SpiningLoader from '../../../constants/SpiningLoader';
import Spinner from '../../../constants/spinner/Spinner';
import ReactModal from "react-modal"

const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    alignItems: "center"
};

const customStyles = {
    content: {
        top: '30%',
        left: '50%',
        right: '50%',
        bottom: 'auto',
        borderRadius:"20px",
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const Orders = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const { ovid } = params
    const navigate = useNavigate();
    const { t } = useTranslation()

    const laung = (localStorage.getItem('laung'));
    const [orderDetail, setOrderDetails] = useState([]);
    const [openModal, setModal] = useState(false);
    const [ibanModal, setIbanModal] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [vendorReason, setVendorReason] = useState("");
    const [orderCancel, setOrderCancel] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false)

    useEffect(() => {
        handleGetOrderDetails()
        dispatch(gettingRatingQuestions())
    }, [ovid, openModal])

    const handleGetOrderDetails = () => {
        getOrderDetails(ovid)
            .then(res => {
                if (res.data.status) {
                    setOrderDetails(res.data.response)
                    setAcceptLoading(false)
                }
            })
            .catch(e => ADMIN.toast.error(e))
    };

    const handleUpdateOrderStatus = (orderDetail) => {
        setAcceptLoading(true)
        const data = {
            "status": "100",
            "order_vendor_id": orderDetail?.order_vendor_id
        }
        updateOrderStatus(data)
            .then(res => {
                if (res.data.status) {
                    handleGetOrderDetails()
                    ADMIN.toast.success(res.data.response.message)
                } else (ADMIN.toast.error(res.data.response.message))
            })
            .catch(e => ADMIN.toast.error(e))
    };

    const handleRejectOrder = (orderDetail) => {
        if (!vendorReason || rejectLoader) {
            return
        } else {
            setRejectLoader(true)
            const data = {
                "status": "106",
                "order_vendor_id": orderDetail?.order_vendor_id,
                "reason": vendorReason,
            }
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        handleGetOrderDetails()
                        setOrderCancel(false)
                        ADMIN.toast.success(res.data.response.message)
                    } else (ADMIN.toast.error(res.data.response.message))
                })
                .catch(e => ADMIN.toast.error(e))
        }
    }
    const handleChat = () => {
        setModal(true)
        dispatch(singelOrderChatDetails(ovid))
    };

    const handleDownload = async (orderDetail) => {
        try {
            const response = await axios.get(
                orderDetail?.quotation,
                { responseType: 'blob' }
            );
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, `${orderDetail?.order_number}.pdf`);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownloadTaxInvoice = async (orderDetail) => {
        try {
            const response = await axios.get(
                orderDetail?.customer_invoice,
                { responseType: 'blob' }
            );
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, `${"C"}${orderDetail?.order_number}.pdf`);
        } catch (error) {
            console.error(error);
        }
    };

    const navigateToProduct = (product) => {
        if (laung == "en") {
            navigate(`/${laung}/${product.url == "" ? "p" : product.url}/p/${product.product_id}`, {
                state: {
                    search: 0
                }
            })
        } else {
            navigate(`/${product.url == "" ? "p" : product.url}/p/${product.product_id}`, {
                state: {
                    search: 0
                }
            })
        }
    };

    const handleClose = () => {
        setModal(false)
    };

    const handlePaymentMethods = (type) => {
        const data = {
            "payment_type": type,
            "order_vendor_id": orderDetail?.order_vendor_id
        }
        if (type === 1) {
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        setIbanModal(true)
                        ADMIN.toast.success(res.data.response.message)
                        return
                    } else (ADMIN.toast.error(res.data.response.message))
                })
                .catch(e => ADMIN.toast.error(e))
        }
        if (type === 2) {
            setLoading(true);
            updateOrderStatus(data)
                .then(res => {
                    if (res.data.status) {
                        if (res.data.response.payment_url) {
                            window.location.href = res.data.response.payment_url;
                            // setLoading(false);
                        } else (ADMIN.toast.error(res.data.response.payment_response.message))
                        return
                    } else {
                        setLoading(false);
                        ADMIN.toast.error(res.data.response.message)
                    }
                })
                .catch(e => { setLoading(false); ADMIN.toast.error(e) })
        }
    }

    const closeIbanModal = () => {
        setIbanModal(false)
    }

    const handleCopingIBAN = (iabn) => {
        navigator.clipboard.writeText(iabn)
        ADMIN.toast.success("IBAN is Copied")
        setIbanModal(false)
    }

    const handleCopingShippingID = (shippingID) => {
        navigator.clipboard.writeText(shippingID)
        ADMIN.toast.success("ID Copied")
    }

    const handleTrackshipment = () => {
        setLoading(true)
        trackOrderShipment(ovid)
            .then((res => {
                if (res.data.status) {
                    setLoading(false)
                    handleGetOrderDetails()

                }
            }))
            .catch(e => (setLoading(false), console.log(e, "the tracking shippment")))
    };

    const handleVendorReason = (e) => {
        e.preventDefault();
        setVendorReason(e.target.value);
    };

    // jSX------------------------------------------------------------------------
    return (
        <>
            {
                loading ? <SpiningLoader /> :
                    orderDetail ?
                        <div className={styles.order_container} >
                            <div className={styles.order_name} >
                                <img src={laung == "ar" ? ChevRight : ChevLeft} alt=""
                                    style={{ marginRight: "15px", cursor: "pointer" }}
                                    onClick={() => navigate(-1)}
                                />
                                {orderDetail?.order_number}
                                {(orderDetail?.status === "100" || orderDetail?.status === "105") ?
                                    orderDetail.given_rating ?
                                        <Rating
                                            value={orderDetail.given_rating}
                                            precision={0.5}
                                            size="large"
                                            readOnly /> :
                                        <VendorRatingModal
                                            orderVendorID={orderDetail.order_vendor_id}
                                            handleGetOrderDetails={handleGetOrderDetails}
                                        /> : ""}
                            </div >
                            <p className={styles.order_status_label} style={{ fontFamily: "Cairo-Bold" }} > {t("order status")} </p>
                            <div className={styles.status_help} >
                                <div className={styles.order_status} >
                                    <img src={OrderStatus} alt="status" style={{ marginRight: "15px" }} />
                                    <div className={styles.date_status} >
                                        <p className={styles.status_label} style={{ fontFamily: "Cairo-Bold" }} >{orderDetail?.status_title}</p>
                                        <p className={styles.status_updated} style={{ fontFamily: "Cairo-Semibold" }} >{orderDetail?.created_on}</p>
                                       {orderDetail.status === "106"  && <p className={styles.status_updated} style={{ fontFamily: "Cairo-Bold" }} >{orderDetail?.reason_for_cancel}</p>}
                                    </div>
                                </div>
                            </div>
                            {(orderDetail.status == "100" && orderDetail.payment_status === "0") &&
                                <div className={styles.payment_details} >
                                    <div className={styles.payment_through} >
                                        <AccountBalanceIcon style={{ fontSize: "45px" }} color='#2a2821' />
                                        <H3>{t("Payment Through")} : </H3>
                                    </div>

                                    <div className={styles.req_button}  >
                                        <div className={styles.chat_count} >
                                            <p className={styles.accept_order} onClick={() => handlePaymentMethods(2)} style={{ fontFamily: "Cairo-Semibold" }} >{t("Payment Gateway")}</p>
                                        </div>

                                        <div className={styles.chat_count} >
                                            <p className={styles.accept_order} onClick={() => handlePaymentMethods(1)} style={{ fontFamily: "Cairo-Semibold" }} >{t("To Bank Account")}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            {((orderDetail.status == "100" || orderDetail.status == "105") && orderDetail.payment_status === "1") &&
                                <div className={styles.payment_details} style={{ backgroundColor: "transparent" }} >
                                    <div className={styles.payment_through} >
                                        <AccountBalanceIcon style={{ fontSize: "45px" }} color='#2a2821' />
                                        <H3>{t("Payment Transaction Details")} :</H3>
                                    </div>
                                    <div className={styles.transaction_details} >
                                        {orderDetail.tid ? <p>{t("Transaction ID")} : {orderDetail.tid}</p> : <p>{t("Payment Mode")} : {t("Bank Method")}</p>}
                                        <p>{t("Transaction Date")} : {orderDetail.payment_date}</p>
                                    </div>
                                </div>
                            }
                            {
                                orderDetail.shipment_id &&
                                <div className={styles.track_shipment}>
                                    <div className={styles.track_shipment_track} >
                                        <LocalShippingIcon style={{ fontSize: "45px" }} color='#2a2821' />
                                        <H3>{t("Track Shippiment")}</H3>:
                                    </div>
                                    <div className={styles.shipping_details} >
                                        <div className={styles.shipping_details_head}>
                                            {/* <h4>OTO ID</h4> */}
                                            <div className={styles.shipping_detail}>
                                                <H3>{t("Delivery Company")} :</H3>
                                                <h6>{orderDetail.delivery_company}</h6>
                                            </div>
                                            <div className={styles.shipping_detail}>
                                                <H3>{t("Delivery Status")} :</H3>
                                                <h6>{orderDetail.delivery_status}</h6>
                                            </div>
                                            <div className={styles.shipping_detail}>
                                                <H3>{t("Tracking ID")} :</H3>
                                                {orderDetail.shipment_id && <h6>  <span onClick={() => handleTrackshipment()} > {orderDetail.shipment_id}</span> <ContentCopyIcon fontSize='medium' onClick={() => handleCopingShippingID(orderDetail.shipment_id)} />  {orderDetail.shipment_id}</h6>}
                                            </div>
                                            <div className={styles.shipping_detail} >
                                                {/* <H3>&nbsp;</H3> */}
                                                <button onClick={() => handleTrackshipment()} style={{ cursor: "pointer" }} >{t("Check Now")}</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }
                            <div className={styles.quatation_details} >
                                {orderDetail.quotation &&
                                    <p className={styles.quatation} style={{ fontFamily: laung == "ar" && "Cairo-Bold" }} >{t("quatation from venderlane")}</p>}
                                <div className={styles.download_request} >
                                    <div className={styles.download_quoation} >
                                        {orderDetail.quotation &&
                                            <div className={styles.request_accept_section} >
                                                <div className={styles.download_section}>
                                                    <div className={styles.download_dis} >
                                                        <img src={PDF} alt="Pdf_format" className={styles.pdf_icon} />
                                                        <p style={{ fontFamily: laung == "ar" && "Cairo-Bold" }}>{orderDetail?.order_number}.pdf</p>
                                                    </div>
                                                    <img src={Download} alt="Download Quotation" className={styles.download_icon} onClick={() => handleDownload(orderDetail)} />
                                                </div>
                                                {
                                                    orderDetail.customer_invoice &&
                                                    <div className={styles.download_section}>
                                                        <div className={styles.download_dis} >
                                                            <img src={PDF} alt="Pdf_format" className={styles.pdf_icon} />
                                                            <p style={{ fontFamily: laung == "ar" && "Cairo-Bold" }}>{t("tax invoice")}</p>
                                                        </div>
                                                        <img src={Download} alt="Download Quotation" className={styles.download_icon} onClick={() => handleDownloadTaxInvoice(orderDetail)} />
                                                    </div>
                                                }

                                                <div className={styles.req_button}  >
                                                    {
                                                        (orderDetail.status == "100" || orderDetail.status == "105") &&
                                                        <div className={styles.chat_count} >
                                                            {orderDetail.new_chat > 0 && <p className={styles.count_number} >{orderDetail.new_chat}</p>}
                                                            <p className={styles.accept_order} onClick={() => handleChat()} style={{ fontFamily: "Cairo-Semibold" }} >{t("Upload Bank Transfer Document")}</p>
                                                        </div>
                                                    }
                                                    {
                                                        orderDetail.status === "91" && <div style={{ display: "flex", columnGap: "10px", width: "100%" }} >
                                                            <p className={styles.accept_order} onClick={() => setOrderCancel(true)} style={{ fontFamily: "Cairo-Semibold" }}> {t("reject order")} </p>
                                                            <p className={styles.accept_order} onClick={() => handleUpdateOrderStatus(orderDetail)} style={{ fontFamily: "Cairo-Semibold" }}> {acceptLoading ? <Spinner /> : t("accept order")} </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <p className={styles.items_in_order} style={{ fontFamily: "Cairo-Bold" }} >{t("items in this order")}</p>
                                        <div className={styles.items_list} > {
                                            orderDetail?.order_detail?.map((data, indi) => {
                                                return (
                                                    <div className={styles.order_item}
                                                        key={indi} >
                                                        <div className={styles.item_info}  >
                                                            <img src={data?.image}
                                                                alt="item"
                                                                onClick={() => navigateToProduct(data)}
                                                                style={{ height: "90px", width: "90px", borderRadius: "10px" }}
                                                            />
                                                            <div>
                                                                <p className={styles.item_name} style={{ fontFamily: "Cairo-Regular" }}>{data?.product_name}</p>
                                                                {data.status !== "107" && (orderDetail.status === "100" || orderDetail.status === "105") ? data.given_rating ?
                                                                    <Rating
                                                                        value={data.given_rating}
                                                                        precision={0.5}
                                                                        size="large"
                                                                        readOnly /> :
                                                                    <RattingModal
                                                                        productDetail={data}
                                                                        handleGetOrderDetails={handleGetOrderDetails}
                                                                    />
                                                                    : ""
                                                                }
                                                            </div>
                                                        </div>
                                                        {orderDetail.status !== "107" && <p className={styles.no_items} style={{ fontFamily: "Cairo-Semibold" }} >{data.status === "107" ? t("out of stock") : `${t("SAR")} ${data?.unit_price} x${data?.quantity}`}</p>}
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                        <div className={styles.order_Address} >
                                            <p className={styles.address_label} style={{ fontFamily: "Cairo-Bold" }} >{t("delivery address")}</p>
                                            <p className={styles.name_number} style={{ fontFamily: "Cairo-Bold" }} > {orderDetail?.order_address?.first_name} {orderDetail?.order_address?.last_name} <span style={{ textAlign: laung === "ar" ? "right" : "left" }}>{orderDetail?.order_address?.country_code} {orderDetail?.order_address?.phone_number}</span></p>
                                            <p className={styles.location} style={{ fontFamily: "Cairo-Semibold" }} >{orderDetail?.order_address?.address} {orderDetail?.order_address?.postal_code}</p>
                                        </div>
                                        <div className={styles.order_prices} >
                                            <div className={styles.prices_left_side} >
                                                <div className={styles.order_price} >
                                                    <p className={styles.price_label} style={{ fontFamily: "Cairo-Bold" }} >{t("total order price")}</p>
                                                    <div className={styles.price_fare} >
                                                        <p className={styles.price} style={{ fontFamily: "Cairo-Bold" }} > {orderDetail?.currency} {orderDetail?.total}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.price_break_up} >
                                                    <p className={styles.summary_label} style={{ fontFamily: "Cairo-Bold" }}>{t("summary")}</p>
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }} >{t("subtotal")}</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }} > SAR {orderDetail.length != 0 && (orderDetail.sub_total).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                    {(orderDetail.bill_value_discount > 0 || orderDetail.discount > 0) && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (Number(orderDetail.bill_value_discount) + Number(orderDetail.discount))}</p>
                                                    </div>}
                                                    {Number(orderDetail.extra_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("Extra Discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.extra_discount)}</p>
                                                    </div>}
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("vat 18%")} :({orderDetail.length != 0 && orderDetail.tax_percent} %)</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (orderDetail.tax).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                    {Number(orderDetail.shipping_charge) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("shipping charge")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.shipping_charge)}</p>
                                                    </div>}
                                                    {Number(orderDetail.shipping_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("shipping discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.shipping_discount)}</p>
                                                    </div>}
                                                 
                                                    {Number(orderDetail.extra_shipping_discount) > 0 && <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("Extra Shipping Discount")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && Number(orderDetail.extra_shipping_discount)}</p>
                                                    </div>}
                                                    <div className={styles.price_category} >
                                                        <p className={styles.category_label} style={{ fontFamily: "Cairo-Semibold" }}>{t("total")}:</p>
                                                        <p className={styles.category_price} style={{ fontFamily: "Cairo-Semibold" }}> SAR {orderDetail.length != 0 && (orderDetail.total).toLocaleString("en-US", { style: "currency", currency: "SAR" })}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <p style={{ fontFamily: "Cairo-Semibold" }}>{t("loading")}</p>
            }
            <Dialog
                maxWidth="lm"
                open={openModal}
                onClose={handleClose}
            >
                <ChatModal orderData={orderDetail} laung={laung} />
            </Dialog>
            <Modal
                open={ibanModal}
                onClose={() => closeIbanModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle}>
                    <H3>{t("IBAN Number")}</H3>
                    {orderDetail.iban ? <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", cursor: "pointer" }} >
                        <P>{orderDetail.iban}</P>
                        <ContentCopyIcon fontSize='medium' onClick={() => handleCopingIBAN(orderDetail.iban)} />
                    </div> : <P>{t("IBAN is Not Availabele")}</P>}
                </Box>
            </Modal>
            <ReactModal
                isOpen={orderCancel}
                onRequestClose={() => setOrderCancel(state => !state)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className={styles.cancel_order} >
                    <H3>{t("Let Us Know the reason for cancel")}</H3>
                    <textarea
                        style={{ width: "100%" }}
                        rows={4}
                        placeholder={t("Write Here")}
                        value={vendorReason}
                        required
                        onChange={(e) => handleVendorReason(e)}
                    />
                    <div className={styles.cancel_buttons} >
                        <p className={styles.cancel_order_btn} onClick={() => setOrderCancel(false)}> {t("Cancel")} </p>
                        <p className={styles.accept_order} onClick={() => vendorReason && handleRejectOrder(orderDetail)}> {rejectLoader ? <Spinner /> : t("update")} </p>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default Orders