import axiosInstance from "./api";

export const getAllAddress = async () => {
  try {
    const response = await axiosInstance.get("select_user_address")
    return response
  } catch(error){
    throw(error)
  }
};

export const addAddress = async (data) => {
  try {
    const response = await axiosInstance.post("add_user_address", data)
    return response
  } catch(error){
    throw(error)
  }
}


export const getSingleAddress = async (ID) => {
  try {
    const response = await axiosInstance.get(`select_user_address.php?id=${ID}`)
    return response
  } catch(error){
    throw(error)
  }
};


export const deleteAddress = async (ID) => {
  try {
    const response = await axiosInstance.get(`delete_user_address.php?id=${ID}`)
    return response
  } catch(error){
    throw(error)
  }
}