import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const fetchHomepageDetails = createAsyncThunk("fetchHomePageDetails", async ({ dID, lang ,pid, setLoading }) => {
  try {
    const response = await axiosInstance.get(`home_page_11?department_id=${dID}&lang=${lang}`);
    localStorage.setItem("department_title", response.data.response.department_title);
    return response.data;
  } catch (error) {
    throw error; 
  }
});

const homePageDetailsSlice = createSlice({
  name: "HomePageDetails",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchHomepageDetails.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchHomepageDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(fetchHomepageDetails.rejected, (state, action) => {
      state.isError = true;
    })
  }
});

export default homePageDetailsSlice.reducer;