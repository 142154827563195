import React, { useEffect } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import { getTermsAndPrivacy } from './app/redux/slice/termsPrivacyPolicy';
import { getDepartMentsAction } from './app/redux/slice/departMentSliceTwo';
import { getAllSpCategories } from './app/redux/slice/spCaterories';
import AppRouter from './app/Routes/AppRouter';
import HelmetAllInOne from './app/Routes/HelmentAllinOne';

function App() {
  const dispatch = useDispatch()
  const laung = localStorage.getItem("laung")

  useEffect(() => {
    dispatch(getDepartMentsAction(laung))
    dispatch(getTermsAndPrivacy(laung))
  }, []);
  useEffect(() => {
    dispatch(getAllSpCategories(laung))
  }, [])
  return (
    <>
      <HelmetAllInOne />
      <AppRouter />
    </>
  );
}

export default App;