import axios from "axios";
import { getLocalToken, getHost, getHeaders } from "./config";

const headers = {};
const axiosInstance = axios.create({
  baseURL: getHost(),
  headers
});
const laung = localStorage.getItem('laung');

const changeLaungOnURL = () => {
  const isEnPath = /\/en(\/|$)/.test(window.location.pathname);
  if(isEnPath && laung == "ar"){
    localStorage.setItem("laung", "en")
  } else if((!isEnPath) && laung == "en"){
    localStorage.setItem("laung", "ar")
  }
}

changeLaungOnURL()
axiosInstance.interceptors.request.use(
  async config => {
    const laung = localStorage.getItem('laung');
    const token = getLocalToken()
    config.headers = getHeaders(config.url);
    config.headers["Accept-Language"] = laung ?? "ar"
    if (token) {
      config.headers.Token = token;
    }
    return config
  }
)

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status == 503) {
      const laung = localStorage.getItem('laung');
      let keysToRemove = ["venlogtoken"];
      keysToRemove.forEach(k => localStorage.removeItem(k))
      window.location.href = laung == 'en' ? `/${laung}/login` : "/login";
      // window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
