import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const fetchProductList = createAsyncThunk("fetchProductList", async (data) => {
  const response = await axiosInstance.post("product", data)
  return response.data;
});


const productListSlice = createSlice({
  name:"productList",
  initialState:{
    isLoading:false,
    data:null,
    isError : false,
  },

  extraReducers : (builder) => {
    builder.addCase(fetchProductList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchProductList.fulfilled, (state, action) =>{
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(fetchProductList.rejected, (state, action) => {
      state.isError = true;
    })
  }
});


export default productListSlice.reducer;