import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Landing/HomePage';
import CategoryViewAll from '../ViewAll/CategoryViewAll';
import SellersProductList from '../sellersDetails/SellersProductList';
import TermsConditions from '../Terms&conditions/TermsConditions';
import PrivacyPolicy from '../Terms&conditions/PrivacyPolicy';
import FAQ from '../Terms&conditions/FAQ';
import AboutUs from '../Terms&conditions/AboutUs';
import OurStory from '../Terms&conditions/OurStory';
import ViewAllMain from '../ViewAll/ViewAllMain';


const MainHomePage = () => {
  const [loading, setIsLoading] = useState(true);


  return (
    <>
      <Routes>
        <Route path='/' element={<New loading={loading} setIsLoading={setIsLoading} />} />
        <Route path="/:subcategory" element={<ViewAllMain />} />
        <Route path="/:subcategory/:sub_sub_category" element={<ViewAllMain />} />
        <Route path="terms&conditions" element={<TermsConditions />} />
        <Route path="faqs" element={<FAQ />} />
        <Route path="privacyplociy" element={<PrivacyPolicy />} />
        <Route path="about_us" element={<AboutUs />} />
        <Route path="our_story" element={<OurStory />} />
        <Route path="all_products/:offertype/:offerkey" element={<ViewAllMain />} />
        <Route path="view_all/:category" element={<CategoryViewAll />} />
        <Route path="sellers_list/:cid" element={<SellersProductList />} />
      </Routes>
    </>
  )
}

export default MainHomePage


const New = ({ loading, setIsLoading }) => {
  return (
    <div>
      <HomePage loading={loading} setIsLoading={setIsLoading} />
    </div>
  )
}