import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getSerchedProducts = createAsyncThunk("serchedproducts", async (data) => {
  const response = await axiosInstance.post("product", data)
  return response.data;
});

const getSerchedProductsSlice = createSlice({
  name:"SearchProductList",
  initialState: {
    isLoading : false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder.addCase(getSerchedProducts.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getSerchedProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(getSerchedProducts.rejected, (state, action) => {
      state.isError = true;
    })
  }
});

export default getSerchedProductsSlice.reducer;