import React from 'react';
import styles from "./PaymentSuccess.module.scss"
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Success from "../../../../assets/Images/svgs/success.svg";

const PaymentSuccess = () => {

  const navigate = useNavigate();
  const params = useParams();
  const {t} = useTranslation();
  let decoded = (atob(params.ovid));
  const orderid = atob(decoded).split("/")[0];
  const trnsID = atob(decoded).split("/")[1];
  const laung = localStorage.getItem("laung");

  const handleNavigate = () => {
    navigate(laung == "en" ? `/${laung}/account/order_status/${orderid}`: `/account/order_status/${orderid}`)
  };

  return (
    <div className={styles.container} >
    <img src={Success} alt ='' style={{height:"126px", width:"126px"}} />
    <p className={styles.heading} >{t("Your payment is Successful")}</p>
    <p className={styles.message} >{t("Your payment transaction number")} : {trnsID}</p>
    <p className={styles.go_home} onClick={() => handleNavigate()} >{t("back to order detilas")}</p>
  </div>
  )
}

export default PaymentSuccess