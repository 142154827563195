import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import fav_icon from '../../../logo.svg'
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ViewAll from './ViewAll';

const ViewAllMain = () => {
  const {params } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [seoData, setSeoData] = useState(null);

  const author = "Venderlane Author";
  const robots = "Vendelane index,Vendelane follow";
  const meta_title = seoData?.seo?.meta_title ? seoData.seo.meta_title : 'فندرلين';
  const meta_description = seoData?.seo?.meta_description ? seoData.seo.meta_description : 'وصف فندرلين';
  const h1 = seoData?.seo?.h1 ? seoData?.seo?.h1 : '';
  const url = seoData?.seo?.url ? seoData?.seo?.url : '';

  useEffect(() => {
    setSeoData(seoData)
    return () => {
      setSeoData(null)
    }
  }, [location.pathname])
  
  return (
    <>
    {seoData  && <Helmet>
        <title>{meta_title}</title>
        <link rel="icon" href={fav_icon} />
        <meta name="description" content={meta_description} />
        <meta name="author" content={author} />
        <meta name="robots" content={robots} />
        <meta property="og:url" content={url} />
        <link rel="canonical" href={`https://www.venderlane.com${location.pathname + location.search}`} />
        <link rel="alternate" hreflang="en-SA" href={`https://www.venderlane.com/en/${url}`} />
        <link rel="alternate" hreflang="ar-SA" href={`https://www.venderlane.com/${url}`} />
        <link rel="alternate" hreflang="x-default" href={`https://www.venderlane.com/${url}`} />
      </Helmet>}
      {seoData && <h1 className='seo'>{h1}</h1>}
      <ViewAll setSeoData={setSeoData} />
    </>
  );
}

export default ViewAllMain;
