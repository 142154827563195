import React from 'react';
import styles from "./RequestSubmitted.module.scss";
import Success from "../../assets/Images/svgs/success.svg";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const RequestSubmitted = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const laung = localStorage.getItem("laung");

  const handleNavigate = () => {
    navigate(laung == "en" ? `/${laung}/account` : "/account")
  };

  return (
    <>
      <div className={styles.container} >
        <img src={Success} alt='' style={{ height: "126px", width: "126px" }} />
        <p className={styles.heading} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }}>{t("order request submitted")}</p>
        <p className={styles.go_home} onClick={() => handleNavigate()} style={{ fontFamily: laung == "ar" && "Cairo-Regular" }} >{t("view orderlist")}</p>
      </div>
    </>
  )
}

export default RequestSubmitted;