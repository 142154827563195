import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
// import Rating from '@mui/material/Rating';
import Rating from 'react-rating';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import styles from "./VendorRattings.module.scss";
import { fetchProductList } from '../../../redux/slice/productListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { submitRatting } from '../../../redux/actions/ratting';
import { orderProductRatting, vendorRatting } from '../../../services/Order.service';
import { useParams } from 'react-router-dom';
import { ADMIN } from '../../../services/admin.service';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import ActiveStar from "../../../assets/Images/svgs/active_star.svg";
import InActiveStar from "../../../assets/Images/svgs/inactive_star.svg";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  bgcolor: '#EDE3CA',
  boxShadow: 24,
  p: 2,
  fontFamily:"Cairo-Semibold"
};

const VendorRatingModal = ({
  productDetail,
  orderVendorID,
  handleGetOrderDetails,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { ovid } = useParams()
  const laung = (localStorage.getItem('laung'));

  const rattingQuestions = useSelector((state) => state?.RatingQuestions?.data?.response);

  const [open, setOpen] = useState(false);
  const [orderRating, setOrderOrderRating] = useState(0);
  const [comment, setComment] = useState("");
  const [questionRatings, setQuestionRatings] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setQuestionRatings([]);
    setOrderOrderRating(0);
  };

  const handleSubmitComment = () => {
    const data = {
      "order_vendor_id": orderVendorID,
      "rating": orderRating,
      "comment": comment,
      "questions": questionRatings
    }
    vendorRatting(data).then(res => {
      if (res.data.status) {
        ADMIN.toast.success(res.data.response.message)
        handleGetOrderDetails(ovid)
        handleClose();
      }
    })
      .catch(e => {
        ADMIN.toast.error(e)
      })
    
  };

  const handleRatingChange = (questionId, newValue) => {
    const updatedRatings = questionRatings.map(item =>
      item.id === questionId ? { ...item, rating: newValue } : item
    );

    if (!updatedRatings.some(item => item.id === questionId)) {
      updatedRatings.push({ id: questionId, rating: newValue });
    }
    setQuestionRatings(updatedRatings);
  };



  return (
    <div style={{ height: "30%" }} >
      <button onClick={handleOpen} className={styles.open_modal} style={{ fontFamily: "Cairo-Semibold" }}> <StarIcon /> {t("rate vendor")}</button>
      <Modal
        open={open}
        style={{ border: "none" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="vendor_rating_modal"
      >
        <Box sx={style}>
          <div className={styles.modal_body} >
            <h1 className={styles.heading} style={{ fontFamily: "Cairo-Bold" }} >
              {t("rate yor experience")}
            </h1>
            {/* <img src={productDetail.image} alt="product" className={styles.product_pic} /> */}
            {/* <p style={{ fontFamily: "Cairo-Semibold" }} className={styles.product_name} >{productDetail.product_name}</p> */}
            {/* <Rating
              value={orderRating}
              size="large"
              precision={0.5}
              onChange={(event, newValue) => {
                setOrderOrderRating(newValue);
              }}
            /> */}
            <Rating
              initialRating={orderRating}
              // fractions={0.5}
              precision={0.5}
              onChange={(event, newValue) => {
                setOrderOrderRating(event);
              }}
              direction={laung == "ar" ? "rtl" : "ltr"}
              emptySymbol={<img src={InActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
              fullSymbol={<img src={ActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
            />

            <TextField
              id="filled-multiline-static"
              label={t("enter comments")}
              multiline
              rows={6}
              defaultValue={comment}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              style={{ width: "70%", marginTop: "1rem", fontFamily: "Cairo-Semibold" }}
            />
            <div className={styles.service_questions} >
              <h3>{t("service questions")}</h3>
              {rattingQuestions && rattingQuestions.map((quest, questindi) => {
                const questionId = quest.id;
                const orderRatingObj = questionRatings.find(item => item.id === questionId);
                const orderRating = orderRatingObj ? orderRatingObj.rating : 0;

                return (
                  <div className={styles.single_question} key={questindi} >
                    <h3>{quest.title}</h3>
                    {/* <Rating
                      value={orderRating}
                      size="large"
                      precision={0.5}
                      onChange={(event, newValue) => handleRatingChange(questionId, newValue)}
                    /> */}
                    <Rating
                      initialRating={orderRating}
                      // readonly
                      // fractions={0.5}
                      precision={0.5}
                      onChange={(event, newValue) => {
                        handleRatingChange(questionId, event);
                      }}
                      direction={laung == "ar" ? "rtl" : "ltr"}
                      emptySymbol={<img src={InActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
                      fullSymbol={<img src={ActiveStar} alt="" className="icon" style={{ height: "20px", width: "25px" }} />}
                    />
                  </div>
                )
              })}
            </div>
            <button style={{ marginTop: "2rem", fontFamily: "Cairo-Semibold", cursor: "pointer" }}
              onClick={() => handleSubmitComment()}
            >
              {t("submit feedback")}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
};

export default VendorRatingModal;
