import axiosInstance from "../../services/api";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getSeoforAllPage = createAsyncThunk("allpage", async (key) => {
    const response = await axiosInstance.get(`seo?page=${key}`)
    return response.data;
});

export const getSeoLanding = createAsyncThunk("landing", async () => {
    const response = await axiosInstance.get("seo?page=landing")
    return response.data;
});

export const getSeoAbout = createAsyncThunk("about", async () => {
    const response = await axiosInstance.get("seo?page=about")
    return response.data;
});

export const getSeoAddress = createAsyncThunk("address", async () => {
    const response = await axiosInstance.get("seo?page=address")
    return response.data;
});

export const getSeoCart = createAsyncThunk("cart", async () => {
    const response = await axiosInstance.get("seo?page=cart")
    return response.data;
});

export const getSeoChats = createAsyncThunk("chats", async () => {
    const response = await axiosInstance.get("seo?page=chats")
    return response.data;
});

export const getSeoFaq = createAsyncThunk("faq", async () => {
    const response = await axiosInstance.get("seo?page=faq")
    return response.data;
});

export const getSeoLogin = createAsyncThunk("login", async () => {
    const response = await axiosInstance.get("seo?page=login")
    return response.data;
});

export const getSeoOrders = createAsyncThunk("orders", async () => {
    const response = await axiosInstance.get("seo?page=orders")
    return response.data;
});

export const getSeoPrivacy = createAsyncThunk("privacy", async () => {
    const response = await axiosInstance.get("seo?page=privacy")
    return response.data;
});

export const getSeoProfile = createAsyncThunk("profile", async () => {
    const response = await axiosInstance.get("seo?page=profile")
    return response.data;
});

export const getSeoRegister = createAsyncThunk("register", async () => {
    const response = await axiosInstance.get("seo?page=register")
    return response.data;
});

export const getSeoStory = createAsyncThunk("story", async () => {
    const response = await axiosInstance.get("seo?page=story")
    return response.data;
});

export const getSeoTerms = createAsyncThunk("terms", async () => {
    const response = await axiosInstance.get("seo?page=terms")
    return response.data;
});

export const getSeoWishlist = createAsyncThunk("wishlist", async () => {
    const response = await axiosInstance.get("seo?page=wishlist")
    return response.data;
});

export const getSeoBlog = createAsyncThunk("blog", async () => {
    const response = await axiosInstance.get("seo?page=blog")
    return response.data;
});

export const get404Status = createAsyncThunk("abc", async () => {
    const response = await axiosInstance.get("abc")
    return response.data;
});

export const updateMetaTagforblog = (name, content) => {
    const metaTag = document.querySelector(`meta[name="${name}"], meta[property="${name}"]`);
    if (metaTag) {
        metaTag.setAttribute('content', content);
    } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute(name.startsWith('og:') ? 'property' : 'name', name);
        newMetaTag.setAttribute('content', content);
        document.head.appendChild(newMetaTag);
    }
};

export const updateCanonicalLinkforblog = (url) => {
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', url);
};
