import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import "./i18n";
import { store } from './app/redux/store';
import { Provider } from 'react-redux';
import ScrollToTop from './app/constants/ScrollToTop';
import BouncingLoader from './app/constants/BouncingLoader';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
// localStorage.setItem('laung', 'ar')
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <HelmetProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}  >
          <Suspense fallback={<div><BouncingLoader /></div>} >
            <ScrollToTop />
            <App />
          </Suspense>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

