import zIndex from "@mui/material/styles/zIndex";
import { toast, Flip } from "react-toastify";



class toastConstructor {
    config = {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "normal--toast",
        transition: Flip,
        theme:"colored",
        zIndex:99
    };

    error = (message) => {
        toast.error(message, this.config);
    };

    info = (message) => {
        toast.info(message, this.config);
    };

    success = (message) => {
        toast.success(message, this.config);
    };

    warn = (message) => {
        toast.warn(message, this.config);
    };

    default = (
        message,
        config = {}
    ) => {
        return toast(message, Object.assign({}, this.config, config));
    };

    update = (toastId, config = {}) => {
        toast.update(toastId, Object.assign({}, this.config, config));
    };

    dismiss = (toastId) => {
        toast.dismiss(toastId);
    };

    done = (toastId) => toast.done(toastId);

    throwError = (
        e,
        fallbackMsg,
        force
    ) => {
        let message = '';
        if (e?.name === "AbortError") return;

        if (!e.response) {
            if (
                e.message?.includes("timeout") &&
                e.message?.includes("exceeded")
            )
                return toast.error("Request Timed Out", this.config);
            return toast.error(
                e.message && force ? e.message : fallbackMsg,
                this.config
            );
        }
        const { data } = e.response;
        if (Array.isArray(data)) message = data[0];
        else if (typeof data === "string") {
            if (data.includes("duplicate key value"))
                message = "Duplicate Entry / Check recycle bin";
            else if (data.includes("ProtectedError"))
                message = "Record already in use";
            else if (data.length > 50) message = fallbackMsg;
            else message = data;
        } else if (typeof data === "object") {
            if (data["detail"]) message = data["detail"];
            else if (data["details"]) message = data["details"];
            else message = JSON.stringify(data);
        }
        toast.error(message, this.config);
    };
}
class admin {
    toast = new toastConstructor();
}

const ADMIN = new admin();

export { ADMIN };