import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../services/api";

export const getFilterDetails = createAsyncThunk("getFilterDetails", async (dID) => {
  const searchTerm = localStorage.getItem("searchTerm");

  let response = await axiosInstance.get(`filter?department_id=${dID.categoryname}
    &category_id=${dID?.catID}&section=${dID.section}
    &search=${searchTerm}&sub_category_id=${dID.subcategoryID}
    &vendor_id=${dID.vendor_id}`);
  return response.data;
});

const getFilterDetailsSlice = createSlice({
  name: "filterDetails",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFilterDetails.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false; 
      })
      .addCase(getFilterDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.isError = false;
      })
      .addCase(getFilterDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default getFilterDetailsSlice.reducer;
