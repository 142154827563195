import React from 'react';
import ContentLoader from 'react-content-loader'

const FilterLoader = () => {
  return (
    
    <ContentLoader 
      viewBox="0 0 500 1000" 
      height="100%" 
      width={"25%"} 
      backgroundColor="#f4efe2"
      foregroundColor="#f4efe2"
    >
        <rect x="20" y="60" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="110" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="220" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="330" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="440" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="550" rx="0" ry="0" width="100%" height="110" />
        <rect x="20" y="660" rx="0" ry="0" width="100%" height="110" />
    </ContentLoader>
  )
}

export default FilterLoader