import axiosInstance from "../../services/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const VenderList = createAsyncThunk("venderlist",  async (vSID) => {
  const response = await axiosInstance.get((!vSID.selectedFilter && !vSID.sortingId && !vSID) ? "serviceprovider" : `serviceprovider?sp_category_id=${vSID.selectedFilter}&sort=${vSID.sortingId?? ""}&city_id=${vSID.selectedCity?? ""}`)
  return response.data;
});

const vendorListSlice = createSlice({
  name:'vendorList',
  initialState:{
    isLoading : false,
    data : null,
    isError : false
  },

  extraReducers: (builder) => {
    builder.addCase(VenderList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(VenderList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });


    builder.addCase(VenderList.rejected, (state, action ) => {
      state.isError = true
    })
  }
})


export default vendorListSlice.reducer;