import React, { useEffect, useState } from 'react';
import styles from "./NotFoundPage.module.scss";
import BannerFlower from "../../assets/Images/pngs/banner_flower.png";
import Flower from "../../assets/Images/svgs/flower.svg";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

const NotFoundPage = ({ setShow }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const laung = localStorage.getItem("laung");
    const dispatch = useDispatch();

    setShow(true);

    useEffect(() => {
        return () => {
            setShow(false);
        }
    }, [dispatch, setShow]);


    const meta_title = "404 Page Not Found";
    const meta_description = "Sorry, the page you are looking for could not be found.";
    const author = "Venderlane Author";
    const robots = "noindex, nofollow";
    const h1 = '404 Page Not Found';
    const url = 'https://www.venderlane.com/';

    return (
        <>
            <Helmet>
                <title>{meta_title}</title>
                <meta name="description" content={meta_description} />
                <meta name="author" content={author} />
                <meta name="robots" content={robots} />
                <meta property="og:url" content={'https://www.venderlane.com/'} />
                <link rel="canonical" href={'https://www.venderlane.com/404.html'} />
                <link rel="alternate" hreflang="en-SA" href={`https://www.venderlane.com/en/`} />
                <link rel="alternate" hreflang="ar-SA" href={`https://www.venderlane.com/`} />
                <link rel="alternate" hreflang="x-default" href={`https://www.venderlane.com/`} />
            </Helmet>

            <h1 className='seo'>{h1}</h1>
            <div className={styles.container}>
                <p className={styles.heading2} style={{ fontFamily: laung === "ar" ? "Cairo-Bold" : "avirain_mediam" }}>{t("404")}</p>
                <p className={styles.heading1} style={{ fontFamily: laung === "ar" ? "Cairo-Bold" : "avirain_mediam" }}>{t("Page Not Found")}</p>
                <p className={styles.heading} style={{ fontFamily: laung === "ar" ? "Cairo-Bold" : "avirain_mediam" }}>{t("Sorry, the page you are looking for could not be found.")}</p>
                <img src={BannerFlower} className={styles.flower_one} alt="Flower" />
                <img src={Flower} className={styles.flower_two} alt="Flower" />
            </div>
        </>
    );
}

export default NotFoundPage;
