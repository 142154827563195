import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from "./SelectAddressModal.module.scss";
import MapOne from '../Map/MapOne';
import { GetAddressDetails } from '../../redux/slice/addressSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitOrder } from '../../services/Order.service';
import { ADMIN } from '../../services/admin.service';
import { getKartDetails } from '../../redux/slice/cartSlice';
import { getUserDetails } from '../../services/Profile';
import { useTranslation } from 'react-i18next';
import { H3 } from "../../constants/CustomText/VendorHeading"
import { menuCounts } from '../../redux/slice/menuCountSlice';
import Spinner from '../../constants/spinner/Spinner';


const SelectAddressModal = ({
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation();
  const laung = (localStorage.getItem('laung'));

  useEffect(() => {
    dispatch(GetAddressDetails())
  }, []);


  const adddressDetails = useSelector((state) => state?.allAddressDetails?.data?.response);

  const [selectedValue, setSelectedValue] = useState(adddressDetails ? adddressDetails[0]?.id : "");
  const [userDetails, setUserDetails] = useState();
  const [showMaps, setShowMaps] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    adddressDetails && setSelectedValue(() => adddressDetails[0]?.id ?? "")
  }, [adddressDetails])


  const handleSubmitOrder = () => {
    setSubmitDisable(true)
    const data = {
      "order_from": 1,
      "address_id": selectedValue,
      "need_shipping": Number(state?.shipping)
    }
    if (selectedValue) {
      SubmitOrder(data)
        .then(res => {
          if (res.data.status) {
            setShowMaps(false)
            dispatch(menuCounts())
            ADMIN.toast.success(res.data.response.message)
            navigate(laung == "en" ? `/${laung}/order_submited` : `/order_submited`, {replace: true})
            setSubmitDisable(false)
          } else {
            ADMIN.toast.warn(res.data.response.message)
            setSubmitDisable(false)
          }
        })
        .catch(e => ADMIN.toast.error(e))
    } else { setSubmitDisable(false); ADMIN.toast.warn(t("Please_Select_address")) }

  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };


  useEffect(() => {
    getUserDetails()
      .then(res => {
        if (res.data.status) {
          setUserDetails(() => res.data.response)
        }
      })
      .catch(e => {
        if (e.response.status == "503") {
          navigate(laung == 'en' ? `/${laung}/login` : "/login")
        }
      })
  }, []);

  return (
    <div className={styles.container} >
      {adddressDetails?.length != 0 &&
        <div className={styles.body} >
          <div className={styles.adding_Address} >
            <H3>{t("select delivery address")}</H3>
            <h1 className={styles.add_below_address} onClick={() => setShowMaps(!showMaps)} style={{ fontFamily: "Cairo-Regular" }} >{!showMaps ? t("add new address"): t('Close Add Address')}</h1>
          </div>
          <div className={styles.available_addresses} >
            {
              adddressDetails && adddressDetails.map((address, indi) => {
                return (
                  <div className={styles.single_Adderss} key={indi}>
                    <div>
                      <input
                        type="radio"
                        value={address.id}
                        checked={selectedValue === address.id}
                        onChange={handleRadioChange}
                        style={{ height: "21px", width: "21px", fontFamily: "Cairo-Regular" }}
                      />
                    </div>
                    <div className={styles.name_location} >
                      <p className={styles.name} style={{ fontFamily: "Cairo-Regular" }}>{address?.first_name} {address?.last_name}</p>
                      <p className={styles.name} style={{ direction: "ltr", textAlign: laung == "ar" ? "right" : "left", fontFamily: "Cairo-Regular" }} > {address?.country_code} {address?.phone_number}</p>
                      <p className={styles.location} style={{ fontFamily: "Cairo-Regular" }}> {address?.landmark}</p>
                      <p className={styles.location} style={{ fontFamily: "Cairo-Regular" }} > {address?.address}</p>
                      <p className={styles.location} style={{ fontFamily: "Cairo-Regular" }} > {address?.postal_code}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <button
            disabled={submitDisable}
            className={styles.buttons_address}
            onClick={() => handleSubmitOrder()}
            style={{ fontFamily: "Cairo-Regular", marginBottom: "10px" }}
          >
            {submitDisable ? <Spinner /> : t("confirm and submit")}
          </button>
        </div>}

      {(showMaps || adddressDetails?.length == 0) && <div className={styles.add_address} >
        <MapOne
          userDetails={userDetails}
          laung={laung}
        />
      </div>}
    </div>
  )
}

export default SelectAddressModal;
