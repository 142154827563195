import React from 'react';
import styles from "./SpiningLoader.module.scss";

const SpiningLoader = () => {
  return (
    <div className={styles.loader}>
    <span></span>
    <span></span>
    <span></span>
  </div>
  )
}

export default SpiningLoader