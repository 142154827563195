import axiosInstance from "./api/index";

export const getHomeapageDetails = async (dID) => {
  try {
    const response = await axiosInstance.get(`home_page?department_id=${dID}`)
    localStorage.setItem("department_title", response.data.response.department_title);

    return response
  } catch (error) {
    throw (error)
  }
};

export const getAboutUsDetails = async (type) => {
  try {
    const response = await axiosInstance.get(`page?page=${type}`)
    return response
  } catch (error) {
    throw (error)
  }
}