import axiosInstance from "./api/index";

export const SubmitOrder = async (data) => {
  try {
    const response = await axiosInstance.post("order_submit", data)
    return response
  } catch (error){
    throw(error)
  }
};


export const getOrderDetails = async (oID) => {
  try {
    const response = await axiosInstance.get(`get_order?order_vendor_id=${oID}`)
    return response
  } catch (error){
    throw(error)
  }
};

export const updateOrderStatus = async (data) =>{
  try {
    const response = await axiosInstance.post("order_status_update", data)
    return response
  }catch(error){
    throw(error)
  }
};

export const sendOrderMessage = async (data) => {
  try {
    const response = await axiosInstance.post("chat", data)
    return response
  } catch(error) {
    throw(error)
  }
}

export const orderProductRatting = async (data) => {
  try {
    const response = await axiosInstance.post("productRating", data)
    return response
  } catch(error) {
    throw(error)
  }
}

export const vendorRatting = async (data) => {
  try {
    const response = await axiosInstance.post("vendorRating", data)
    return response
  } catch(error) {
    throw(error)
  }
}


export const trackOrderShipment = async (oID) => {
  try {
    const response = await axiosInstance.get(`get_order?order_vendor_id=${oID}&oto_tracking=1`)
    return response
  } catch (error){
    throw(error)
  }
};
